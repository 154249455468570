import { HttpMethod, requestOptions } from "../../../utils/api/request-options";
import { handleResponse, handleResponseNoContent } from "../../../utils/api/response-handler";
import { BadgeAssignment } from "../model/badge-assignment";
import { ModuleAssignment } from "../model/module-assignment";
import { WorkItemAssignment } from "../model/work-item-assignment";
import { WorkItemAssignmentDetalis } from "../model/work-item-assignment-details";

export function getWorkItemAssignments(
    from: string, 
    to: string, 
    cohortId?: number | undefined,
    moduleId?: string | undefined,
    badgeId?: string | undefined,
    studentId?: number | undefined,
    evaluatorId?: number | undefined
): Promise<WorkItemAssignment[]> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/assignment/${from}/${to}/list?` + 
        `cohortId=${cohortId ?? ''}&` +
        `moduleId=${moduleId ?? ''}&` +
        `badgeId=${badgeId ?? ''}&` +
        `studentId=${studentId ?? ''}&` +
        `evaluatorId=${evaluatorId ?? ''}`, requestOptions(HttpMethod.GET))
        .then(response => {
            return handleResponse<WorkItemAssignment[]>(response)
        });
}

export function getWorkItemAssignment(workItemId: number): Promise<WorkItemAssignmentDetalis> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/assignment/${workItemId}`, requestOptions(HttpMethod.GET))
        .then(response => {
            return handleResponse<WorkItemAssignmentDetalis>(response)
        });
}

export function deleteWorkItemAssignment(workItemId: number): Promise<void> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/assignment/${workItemId}`, requestOptions(HttpMethod.DELETE))
        .then(response => {
            return handleResponseNoContent(response)
        });
}

export function getStudentAssignments(): Promise<ModuleAssignment[]> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/assignment/student/list`, requestOptions(HttpMethod.GET))
        .then(response => {
            return handleResponse<ModuleAssignment[]>(response)
        });
}

export function submitStudentAssignment(badgeAssignment: BadgeAssignment): Promise<BadgeAssignment> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/assignment/student`, requestOptions(HttpMethod.POST, badgeAssignment))
        .then(response => {
            return handleResponse<BadgeAssignment>(response)
        });
}

export function dismissStudentAssignment(badgeId: string): Promise<BadgeAssignment> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/assignment/student/dismiss/${badgeId}`, requestOptions(HttpMethod.POST))
        .then(response => {
            return handleResponse<BadgeAssignment>(response)
        });
}

export function updateStudentAssignment(badgeAssignment: BadgeAssignment): Promise<BadgeAssignment> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/assignment/student`, requestOptions(HttpMethod.PUT, badgeAssignment))
        .then(response => {
            return handleResponse<BadgeAssignment>(response)
        });
}

export function deleteStudentAssignment(workItemId: number): Promise<void> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/assignment/student/${workItemId}`, requestOptions(HttpMethod.DELETE))
        .then(response => {
            return handleResponseNoContent(response)
        });
}

export function getEvaluatorAssignments(): Promise<ModuleAssignment[]> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/assignment/evaluator/list`, requestOptions(HttpMethod.GET))
        .then(response => {
            return handleResponse<ModuleAssignment[]>(response)
        });
}

export function updateEvaluatorAssignment(badgeAssignment: BadgeAssignment): Promise<BadgeAssignment> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/assignment/evaluator`, requestOptions(HttpMethod.PUT, badgeAssignment))
        .then(response => {
            return handleResponse<BadgeAssignment>(response)
        });
}

export function reassignEvaluatorAssignment(workItemId: number, evaluatorId?: number | undefined): Promise<void> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/assignment/evaluator/reassign/${workItemId}/${evaluatorId ?? ""}`, requestOptions(HttpMethod.PUT))
        .then(response => {
            return handleResponseNoContent(response)
        });
}