import React, { useEffect, useState } from "react";
import { useToast } from "../../../../context/toast/toast-provider";
import commonStyles from "../../../style/common.module.css";
import BaseForm from "../../../../component/form/base-form";
import { BadgeAssignment } from "../../model/badge-assignment";
import { ModuleAssignment } from "../../model/module-assignment";
import { SelectItem } from "../../../../component/form/select-item";
import { dismissStudentAssignment, submitStudentAssignment } from "../../service/assignment-service";
import { studentSubmitSelectFieldDefinitions } from "./student-submit-select-field-definition";
import { studentSubmitFieldDefinitions } from "./student-submit-field-definition";
import { Alert } from "@mui/material";
import { 
    ASSIGNMENT_QUERY_PARAMETER_BADGEID,
    ASSIGNMENT_QUERY_PARAMETER_MODULEID, 
    ASSIGNMENT_QUERY_PARAMETER_TAB_VALUE_STUDENT_SUBMIT 
} from "../../utils/assignment-constants";
import { useSearchParams } from "react-router-dom";
import { getBadgeTerminology, getModuleCourseLevel, getModuleTerminology, getGeneralCourseLevel } from "../../utils/terminology";
import { CourseLevel } from "../../../-education/enum/course-level";
import { updateSearchParams } from "../../utils/assignment-navigation";

export interface AssignmentFilter {
    moduleId?: string | undefined,
    badgeId?: string | undefined
}

export default function StudentSubmit({
    generalCourseLevel,
    unsubmittedModuleAssignments,
    updateModuleAssignments,
    enableDismiss
}: {
        generalCourseLevel: CourseLevel,
        unsubmittedModuleAssignments: ModuleAssignment[],
        updateModuleAssignments(badgeAssignment: BadgeAssignment): void,
        enableDismiss: boolean
}) {
    const [searchParams, setSearchParams] = useSearchParams();
    const moduleId = searchParams.get(ASSIGNMENT_QUERY_PARAMETER_MODULEID);
    const badgeId = searchParams.get(ASSIGNMENT_QUERY_PARAMETER_BADGEID);
    const [filter, setFilter] = useState<AssignmentFilter>({
        moduleId: moduleId ?? undefined,
        badgeId: badgeId ?? undefined
    });

    const [submitting, setSubmitting] = useState(false);
    const [dismissing, setDismissing] = useState(false);
    const [submitBadgeAssignment, setSubmitBadgeAssignment] = useState<BadgeAssignment>({ workingBadgePath: "" });

    const toast = useToast();

    const studentCourseLevel = unsubmittedModuleAssignments.length > 0 ? getGeneralCourseLevel(unsubmittedModuleAssignments) : generalCourseLevel;

    useEffect(() => {
        const badge = unsubmittedModuleAssignments.flatMap(_ => _.badges).find(_ => _.badgeId === filter.badgeId);

        if (badge !== undefined && badge.moduleId === filter.moduleId) {
            setSubmitBadgeAssignment({ ...badge, workingBadgePath: "" });
        } else {
            setSubmitBadgeAssignment({ workingBadgePath: "" });
        }
    }, [unsubmittedModuleAssignments, filter]);

    useEffect(() => {
        updateSearchParams(searchParams, setSearchParams, submitBadgeAssignment, ASSIGNMENT_QUERY_PARAMETER_TAB_VALUE_STUDENT_SUBMIT);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submitBadgeAssignment]);

    const getSubmitSelectFieldDefinitions = () => {
        const modules = unsubmittedModuleAssignments.map(_ => {
            let selectItem: SelectItem = {
                id: _.moduleId,
                name: _.moduleName
            }
            return selectItem;
        });

        const selectedModule = unsubmittedModuleAssignments?.find(_ => _.moduleId === filter.moduleId);

        if (filter.moduleId !== undefined && selectedModule === undefined) {
            setFilter({ ...filter, moduleId: undefined });
        }

        studentSubmitSelectFieldDefinitions.find(_ => _.id === "moduleId")!.values = modules;
        studentSubmitSelectFieldDefinitions.find(_ => _.id === "badgeId")!.disabled = true;

        if (filter.moduleId !== undefined) {
            const badges = selectedModule?.badges.filter(_ => _.submissionDate === undefined || _.canResubmit === true).map(_ => {
                let selectItem: SelectItem = {
                    id: _.badgeId,
                    name: (_.canResubmit === true ? `${_.badgeName} (Resubmit)` : _.badgeName) ?? ''
                }
                return selectItem;
            });

            if (filter.badgeId !== undefined && badges?.find(_ => _.id === filter.badgeId) === undefined) {
                setFilter({ ...filter, badgeId: undefined });
            }

            studentSubmitSelectFieldDefinitions.find(_ => _.id === "badgeId")!.name = getBadgeTerminology(selectedModule?.moduleCourseLevelId ?? studentCourseLevel);
            studentSubmitSelectFieldDefinitions.find(_ => _.id === "badgeId")!.values = badges;
            studentSubmitSelectFieldDefinitions.find(_ => _.id === "badgeId")!.disabled = selectedModule?.isEvaluatorAvailable === false ? true : false;
        }

        studentSubmitSelectFieldDefinitions.find(_ => _.id === "moduleId")!.name = getModuleTerminology(selectedModule?.moduleCourseLevelId ?? studentCourseLevel);

        return studentSubmitSelectFieldDefinitions;
    }

    const getSubmitFieldDefinitions = () => {
        studentSubmitFieldDefinitions.find(_ => _.id === "workingBadgePath")!.name = `${getBadgeTerminology(getModuleCourseLevel(unsubmittedModuleAssignments, submitBadgeAssignment.badgeId))} path`;

        return studentSubmitFieldDefinitions;
    }

    const handleSubmit = (badgeAssignment: BadgeAssignment) => {
        setSubmitting(true);

        const badgeAssignmentRequest = { ...badgeAssignment };
        const moduleCourseLevel = getModuleCourseLevel(unsubmittedModuleAssignments, badgeAssignment.badgeId);
        const hasWorkingBadgePath = badgeAssignmentRequest.workingBadgePath !== undefined && badgeAssignmentRequest.workingBadgePath.replaceAll(' ', '').length !== 0;
        badgeAssignmentRequest.workingBadgePath = hasWorkingBadgePath ? badgeAssignmentRequest.workingBadgePath : 'No path';

        submitStudentAssignment(badgeAssignmentRequest).then(result => {
            toast.addToast(`Great work! ${getBadgeTerminology(moduleCourseLevel)} ${badgeAssignment.badgeName} has been submitted`, "success");

            result.moduleId = badgeAssignmentRequest.moduleId;
            updateModuleAssignments(result);

            setSubmitBadgeAssignment({ workingBadgePath: "" });
            setFilter({ ...filter, badgeId: undefined });
            setSubmitting(false);
        }).catch(() => {
            toast.addToast(`Unable to submit ${getBadgeTerminology(moduleCourseLevel, true)} ${badgeAssignment.badgeName}`, "error");
            setSubmitting(false);
        });
    }

    const handleDismiss = () => {
        setDismissing(true);
        const moduleCourseLevel = getModuleCourseLevel(unsubmittedModuleAssignments, submitBadgeAssignment.badgeId);

        dismissStudentAssignment(submitBadgeAssignment.badgeId!).then(result => {
            
            toast.addToast(`${getBadgeTerminology(moduleCourseLevel)} ${submitBadgeAssignment.badgeName} has been dismissed`, "success");

            result.moduleId = submitBadgeAssignment.moduleId;
            updateModuleAssignments(result);

            setSubmitBadgeAssignment({ workingBadgePath: "" });
            setFilter({ ...filter, badgeId: undefined });
            setDismissing(false);
        }).catch(() => {
            toast.addToast(`Unable to dismiss ${getBadgeTerminology(moduleCourseLevel)} ${submitBadgeAssignment.badgeName}`, "error");
            setDismissing(false);
        });
    }

    const buildEvaluatorWarning = () => {
        const selectedModule = unsubmittedModuleAssignments.find(_ => _.moduleId === filter.moduleId);
        if (selectedModule !== undefined && selectedModule.isEvaluatorAvailable === false) {
            return <div>
                <Alert severity="error" className={commonStyles.alert} >
                    <span>
                        There are no active <b>evaluators</b> available to <b>review</b> your submitted {getBadgeTerminology(selectedModule.moduleCourseLevelId, true)} <b>{submitBadgeAssignment.badgeName}</b> for {getModuleTerminology(selectedModule.moduleCourseLevelId, true)} <b>{selectedModule.moduleName}</b>
                    </span>
                </Alert>
            </div>
        }
    }

    return (
        <React.Fragment>
            {unsubmittedModuleAssignments.length > 0 && <BaseForm
                model={filter}
                setModel={setFilter}
                fields={getSubmitSelectFieldDefinitions()}
            />}
            {submitBadgeAssignment.badgeId !== undefined && <BaseForm
                model={submitBadgeAssignment}
                setModel={setSubmitBadgeAssignment}
                fields={getSubmitFieldDefinitions()}
                submitEntity={handleSubmit}
                submitting={submitting}
                submitButtonText='Submit'
                deleteEntity={enableDismiss ? handleDismiss : undefined}
                deleting={dismissing}
                deleteButtonText='Dismiss'
                deleteConfirmationText={`Are you sure you want to dismiss 
                    ${getBadgeTerminology(getModuleCourseLevel(unsubmittedModuleAssignments, submitBadgeAssignment.badgeId), true)} <b>
                    ${submitBadgeAssignment.badgeName}</b>?`}
            />}
            {unsubmittedModuleAssignments.length === 0 && <div>
                <span>
                    You have no {getBadgeTerminology(studentCourseLevel, true)} to <b>Submit</b>. You can look at your previous {getBadgeTerminology(studentCourseLevel, true)} submissions under your <b>Journal</b>.
                </span>
            </div>}
            {buildEvaluatorWarning()}
        </React.Fragment>
    );
}
