import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import ModuleEvaluatiorList from "./-module-evaluators/module-evaluator-list";
/*import ModulePrerequisiteHandle from "../-module-prerequisites/module-prerequisite-handle";*/
import ModuleCohortList from "./-module-cohorts/module-cohort-list";
import ModuleHandler from "./-module-handler/module-handler";
import { getModule } from "../service/module-service";
import { Module } from "../model/module";
import BadgeList from "./-module-badges/-badge-list/badge-list";
import ViewLoader from "../../../component/misc/view-loader";
import { useCache } from "../../../context/cache/cache-provider";
import { ASSIGNMENT_MANAGEMENT, EDUCATION_MANAGEMENT_MODULE_EDIT, PARAMETER_MODULE_ID } from "../../../infrastructure/route";
import AssignmentList from "../../-assignment/-assignment-management/-assignment-list/assignment-list";
import { useAuth } from "../../../context/auth/auth-provider";

export const MODULE_TAB_STATE: string = "MODULE_TAB_STATE";
export const MODULE_TAB_STATE_ADD_EDIT: string = "MODULE_TAB_STATE_ADD_EDIT";
export const MODULE_TAB_STATE_BADGES: string = "MODULE_TAB_STATE_BADGES";
/*export const MODULE_TAB_STATE_PREREQUISITES: string = "MODULE_TAB_STATE_PREREQUISITES";*/
export const MODULE_TAB_STATE_EVALUATORS: string = "MODULE_TAB_STATE_EVALUATORS";
export const MODULE_TAB_STATE_COHORTS: string = "MODULE_TAB_STATE_COHORTS";
export const MODULE_TAB_STATE_ASSIGNMENTS: string = "MODULE_TAB_STATE_ASSIGNMENTS";

export default function ModuleView() {
    const { moduleId } = useParams();
    const newModel = moduleId === undefined;

    const [tab, setTab] = useState<string>(localStorage.getItem(MODULE_TAB_STATE) ?? MODULE_TAB_STATE_ADD_EDIT);
    const [loading, setLoading] = useState<boolean>(true);
    const [module, setModule] = useState<Module>({
        moduleId: moduleId ?? "",
        title: "",
        active: true
    });

    const cache = useCache();
    const auth = useAuth();

    useEffect(() => {
        cache.update({ key: PARAMETER_MODULE_ID, value: module.title });
    }, [cache, module]);

    useEffect(() => {
        if (!newModel) {
            setLoading(true);
            getModule(moduleId).then(result => {
                setModule(result);
                setLoading(false);
            });
        } else {
            setLoading(false);
        }
    }, [moduleId, newModel]);

    const changeTab = (newTab: string) => {
        localStorage.setItem(MODULE_TAB_STATE, newTab);
        setTab(newTab);
    }

    return (
        <React.Fragment>
            <ViewLoader loading={loading} />
            {loading === false && <TabContext value={tab}>
                {moduleId !== undefined && <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={(_: React.SyntheticEvent, newTab: string) => changeTab(newTab)}>
                        <Tab label={newModel === true ? "Add" : "Edit"} value={MODULE_TAB_STATE_ADD_EDIT} />
                        {!newModel && <Tab label="Badges" value={MODULE_TAB_STATE_BADGES} />}
                        {/*{!newModel && <Tab label="Prerequisites" value={MODULE_TAB_STATE_PREREQUISITES} />}*/}
                        {!newModel && <Tab label="Evaluators" value={MODULE_TAB_STATE_EVALUATORS} />}
                        {!newModel && <Tab label="Cohorts" value={MODULE_TAB_STATE_COHORTS} />}
                        {!newModel && auth.hasRouteAccess(ASSIGNMENT_MANAGEMENT) && <Tab label="Assignments" value={MODULE_TAB_STATE_ASSIGNMENTS} />}
                    </TabList>
                </Box>}
                <TabPanel value={MODULE_TAB_STATE_ADD_EDIT}>
                    <ModuleHandler
                        newModel={newModel}
                        module={module}
                        setModule={setModule}
                    />
                </TabPanel>
                <TabPanel value={MODULE_TAB_STATE_BADGES}>
                    <BadgeList module={module} />
                </TabPanel>
                {/*<TabPanel value={MODULE_TAB_STATE_PREREQUISITES}>*/}
                {/*    <ModulePrerequisiteHandle module={module} />*/}
                {/*</TabPanel>*/}
                <TabPanel value={MODULE_TAB_STATE_EVALUATORS}>
                    <ModuleEvaluatiorList module={module} />
                </TabPanel>
                <TabPanel value={MODULE_TAB_STATE_COHORTS}>
                    <ModuleCohortList module={module} />
                </TabPanel>
                <TabPanel value={MODULE_TAB_STATE_ASSIGNMENTS}>
                    <AssignmentList 
                        moduleId={module.moduleId}
                        originUrl={`${EDUCATION_MANAGEMENT_MODULE_EDIT.replace(PARAMETER_MODULE_ID, moduleId ?? "")}`}
                    />
                </TabPanel>
            </TabContext>}
        </React.Fragment>
    );
}
