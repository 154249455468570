import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import commonStyles from "../../../style/common.module.css";
import { Dayjs } from "dayjs";
import { EXAMINE_SURVEY_SUBMIT_VIEW, PARAMETER_SURVEY_SUBMIT_ID } from "../../../../infrastructure/route";
import { useCache } from "../../../../context/cache/cache-provider";
import { SurveySubmit } from "../../model/survey-submit";
import { getSurveySubmits } from "../../service/survey-submit-service";
import { columns } from "./survey-submit-list-column-definition";
import { BaseList } from "../../../../component/list/base-list";

export default function SurveySubmitList() {
    const [loading, setLoading] = useState(true);
    const [surveySubmits, setSurveySubmits] = useState<SurveySubmit[]>([]);
    const [from, setFrom] = useState<Dayjs | null>(null);
    const [to, setTo] = useState<Dayjs | null>(null);

    const navigate = useNavigate();
    const cache = useCache();

    useEffect(() => {
        if (from !== null && to !== null && to.diff(from, 'day') >= 0) {
            setLoading(true);
            getSurveySubmits(from.format('YYYY-MM-DD'), to.add(1, 'day').format('YYYY-MM-DD')).then(result => {
                setSurveySubmits(result ?? []);
                setLoading(false);
            });
        }
    }, [from, to]);

    const openRead = (surveySubmitId: string) => {
        const surveySubmit = surveySubmits.find(_ => _.id === Number(surveySubmitId));
        cache.update({ key: PARAMETER_SURVEY_SUBMIT_ID, value: surveySubmit?.surveyTitle ?? '' });

        navigate(EXAMINE_SURVEY_SUBMIT_VIEW.replace(PARAMETER_SURVEY_SUBMIT_ID, `${surveySubmitId}`));
    }

    return (
        <div className={commonStyles.content} >
            <BaseList
                storageKey="SURVEY_SUBMIT"
                rows={surveySubmits}
                columns={columns}
                loading={loading}
                localeText={{ noRowsLabel: "No submitted surveys" }}
                getRowId={(row) => `${row?.id}`}
                onRowClick={(e) => openRead(e.row?.id)}
                from={from}
                to={to}
                setFrom={setFrom}
                setTo={setTo}
            />
        </div>
    );
}
