import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Alert } from "@mui/material";
import { studentFieldDefinitions } from "./student-field-definition";
import { User } from "../../model/user";
import { useToast } from "../../../../context/toast/toast-provider";
import { ACCOUNT_MANAGEMENT_STUDENT_EDIT, ACCOUNT_MANAGEMENT_STUDENT_LIST, PARAMETER_STUDENT_ID } from "../../../../infrastructure/route";
import BaseForm from "../../../../component/form/base-form";
import { addUserStudent, deleteUserStudent, updateUserStudent } from "../../service/student-service";
import { Cohort } from "../../../-education/model/cohort";
import { getCohorts } from "../../../-education/service/cohort-service";
import React from "react";
import ViewLoader from "../../../../component/misc/view-loader";
import { SelectItem } from "../../../../component/form/select-item";
import { studentRoles } from "../../../enum/role";
import { Gender } from "../../../enum/gender";

export default function StudentHandler({
    newModel,
    student,
    setStudent
}: {
        newModel: boolean,
        student: User,
        setStudent: React.Dispatch<React.SetStateAction<User>>
}) {
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [cohorts, setCohorts] = useState<Cohort[]>([]);

    const toast = useToast();
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        getCohorts().then(result => {
            setCohorts(result);
            setLoading(false);
        })
    }, []);

    const getStudentFieldDefinitions = () => {
        let cohortsItems = [{
            id: 0,
            name: "None"
        } as SelectItem]
        cohortsItems = cohortsItems.concat(cohorts
            .map(_ => {
                return {
                    id: _.id,
                    name: _.name
                } as SelectItem
            }))
        studentFieldDefinitions.find(_ => _.id === "cohortId")!.values = cohortsItems;

        if(student.hasNonStudentRole === true) {
            studentFieldDefinitions.find(_ => _.id === "name")!.disabled = true;
            studentFieldDefinitions.find(_ => _.id === "phone")!.disabled = true;
            studentFieldDefinitions.find(_ => _.id === "email")!.disabled = true;
            studentFieldDefinitions.find(_ => _.id === "gender")!.disabled = true;
            studentFieldDefinitions.find(_ => _.id === "gender")!.required = false;
            studentFieldDefinitions.find(_ => _.id === "cohortId")!.disabled = false;
            studentFieldDefinitions.find(_ => _.id === "active")!.disabled = true;
        } else {
            studentFieldDefinitions.find(_ => _.id === "name")!.disabled = false;
            studentFieldDefinitions.find(_ => _.id === "phone")!.disabled = false;
            studentFieldDefinitions.find(_ => _.id === "email")!.disabled = false;
            studentFieldDefinitions.find(_ => _.id === "gender")!.disabled = false;
            studentFieldDefinitions.find(_ => _.id === "gender")!.required = true;
            studentFieldDefinitions.find(_ => _.id === "cohortId")!.disabled = student.gender === undefined;
            studentFieldDefinitions.find(_ => _.id === "active")!.disabled = false;
        }

        return studentFieldDefinitions;
    }

    const handleSubmit = (studentUser: User) => {
        setSubmitting(true);

        var roles = studentUser.roles.filter(r => studentRoles.find(sr => r === sr) === undefined);
        studentUser.roles = roles;
        studentUser.roles.push(studentUser.studentRole!);
        studentUser.cohortId = studentUser.cohortId === 0 ? undefined : studentUser.cohortId;

        if (newModel) {
            addUserStudent(studentUser).then(result => {
                toast.addToast(`Student ${result.name} created`, "success");
                result.studentRole = studentRoles.find(sr => result.roles.find(r => r === sr));
                result.cohortId = result.cohortId === undefined ? 0 : result.cohortId;
                setStudent(result);
                setSubmitting(false);

                navigate(ACCOUNT_MANAGEMENT_STUDENT_EDIT.replace(PARAMETER_STUDENT_ID, `${result.id}`))
            }).catch((error) => {
                if (error?.apiErrorResponse?.errorCode === 409) {
                    toast.addToast(`The email ${studentUser.email} already exists`, "error");
                } else {
                    toast.addToast(`Unable to create student`, "error");
                }
                setSubmitting(false);
            });
        } else {
            updateUserStudent(studentUser).then(result => {
                toast.addToast(`Student ${result.name} updated`, "success");
                result.studentRole = studentRoles.find(sr => result.roles.find(r => r === sr));
                result.cohortId = result.cohortId === undefined ? 0 : result.cohortId;
                setStudent(result);
                setSubmitting(false);
            }).catch((error) => {
                if (error?.apiErrorResponse?.errorCode === 409) {
                    toast.addToast(`The email ${studentUser.email} already exists`, "error");
                } else {
                    toast.addToast(`Unable to update student ${studentUser.name}`, "error");
                }
                setSubmitting(false);
            });
        }
    }

    const deleteWarnings = () => {
        const warnings = [
            student.hasAttendances === true ? `<b>${student.name}</b> has <b>attendance</b>, deleting the student will also <b>delete the attendance</b>.` : '',
            student.hasAssignments === true ? `<b>${student.name}</b> has <b>assignments</b>, deleting the student will also <b>delete the assignments</b>.` : '',
            student.hasPresentations === true ? `<b>${student.name}</b> has given <b>presentations</b>, deleting the student will also <b>delete the presentations</b>.` : '',
            student.cohortId !== undefined ? `<b>${student.name}</b> as a student is attending a <b>cohort ${student.cohortName}</b>, deleting the student will also <b>remove it from the cohort</b>.` : '',
        ].filter(_ => _.length > 0)

        return warnings.length > 0 ? warnings : undefined;
    }

    const handleDelete = () => {
        if (!newModel) {
            setDeleting(true);
            deleteUserStudent(Number(student.id)).then(() => {
                toast.addToast(`Student ${student.name} deleted`, "success");
                setDeleting(false);
                navigate(ACCOUNT_MANAGEMENT_STUDENT_LIST);
            }).catch(() => {
                toast.addToast(`Unable to delete student ${student.name}`, "error");
                setDeleting(false);
            });
        }
    }

    const cohortGenderWarning = () => {
        const selectedCohortGender = cohorts.find(_ => _.id === student.cohortId)?.gender;
        return selectedCohortGender !== undefined && 
            selectedCohortGender !== Gender.All &&
            student.gender !== undefined &&
            selectedCohortGender !== student.gender;
    }

    return (
        <React.Fragment>
            <ViewLoader loading={loading} />
            {loading === false && <BaseForm
                name={student.name}
                model={student}
                setModel={setStudent}
                fields={getStudentFieldDefinitions()}
                submitEntity={handleSubmit}
                deleteEntity={!newModel && !student.hasNonStudentRole ? handleDelete : undefined}
                submitting={submitting}
                deleting={deleting}
                deleteWarnings={deleteWarnings()}
            >
                <React.Fragment>
                    {cohortGenderWarning() === true && <Alert severity="warning">
                        <span dangerouslySetInnerHTML={{ __html: `Cohort gender is <b>${Gender[cohorts.find(_ => _.id === student.cohortId)!.gender!]}</b> but student gender is <b>${Gender[student.gender!]}</b>.` }}></span>
                    </Alert>}
                    <span style={{ display: student.hasNonStudentRole === true ? 'inline': 'none'}}>
                        {student.name} has more responsibility then beening a student. You can only update <b>Cohort</b> and <b>Student type</b>.
                    </span>
                </React.Fragment>
            </BaseForm>}
        </React.Fragment>
    );
}
