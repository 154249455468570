import { FieldDefinition } from "../../../../component/form/field-definition";

export const assignmentFieldDefinitions: FieldDefinition[] = [
    {
        id: "cohortName",
        name: "Cohort",
        type: "info",
        required: false,
        disabled: false,
        hidden: true
    },
    {
        id: "cohortLink",
        name: "Cohort",
        type: "internalLink",
        required: false,
        disabled: false,
        hidden: true
    },
    {
        id: "moduleName",
        name: "Module",
        type: "info",
        required: false,
        disabled: false,
        hidden: true
    },
    {
        id: "moduleLink",
        name: "Module",
        type: "internalLink",
        required: false,
        disabled: false,
        hidden: true
    },
    {
        id: "badgeName",
        name: "Badge",
        type: "info",
        required: false,
        disabled: false,
        hidden: true
    },
    {
        id: "badgeLink",
        name: "Badge",
        type: "internalLink",
        required: false,
        disabled: false,
        hidden: true
    },
    {
        id: "badgeInstruction",
        name: "Badge instruction",
        type: "info",
        required: false,
        disabled: false
    },
    {
        id: "badgeAcceptanceCriteria",
        name: "Badge acceptance criteria",
        type: "info",
        required: false,
        disabled: false
    },
    {
        id: "badgeAllowResubmit",
        name: "Badge allow resubmit",
        type: "info",
        required: false,
        disabled: false
    },
    {
        id: "studentName",
        name: "Student",
        type: "info",
        required: false,
        disabled: false
    },
    {
        id: "submissionDate",
        name: "Submission date",
        type: "info",
        required: false,
        disabled: false
    },
    {
        id: "evaluatorName",
        name: "Evaluator",
        type: "info",
        required: false,
        disabled: false
    },
    {
        id: "evaluatedDate",
        name: "Evaluated date",
        type: "info",
        required: false,
        disabled: false
    },
    {
        id: "isEvaluatorAvailible",
        name: "Evaluator availible",
        type: "info",
        required: false,
        disabled: false
    },
    {
        id: "rating",
        name: "Rating",
        type: "info",
        required: false,
        disabled: false
    },
    {
        id: "comment",
        name: "Comment",
        type: "info",
        required: false,
        disabled: false
    },
    {
        id: "workingBadgePath",
        name: "Working badge path",
        type: "info",
        required: false,
        disabled: false
    },
    {
        id: "assignmentStatusName",
        name: "Assignment status",
        type: "info",
        required: false,
        disabled: false
    },
    {
        id: "canResubmit",
        name: "Can resubmit",
        type: "info",
        required: false,
        disabled: false
    },
    {
        id: "resubmits",
        name: "Resubmits",
        type: "info",
        required: false,
        disabled: false
    },
    {
        id: "resubmittDate",
        name: "Last resubmitt date",
        type: "info",
        required: false,
        disabled: false
    },
    {
        id: "newEvaluatorId",
        name: "Evaluator reassign",
        type: "select",
        required: true,
        disabled: false
    },
];