import React, { useEffect, useState } from "react";
import commonStyles from "../../style/common.module.css";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import DashboardManagerFilterHander from "./-filter/dashboard-manager-filter-handler";
import { DashboardManagerFilter } from "../model/dashboard-manger-filter";
import CohortStudentModuleAverageRatingList from "./-list/-cohort-student-module-average-rating-list/cohort-student-module-average-rating-list";
import CohortStudentModuleBadgeRatingList from "./-list/-cohort-student-module-badge-rating-list/cohort-student-module-badge-rating-list";
import CohortStudentModuleBadgeUnsubmittedList from "./-list/-cohort-student-module-badge-unsubmitted-list/cohort-student-module-badge-unsubmitted-list";
import CohortStudentModuleBadgeUnevaluatedList from "./-list/-cohort-student-module-badge-unevaluated/cohort-student-module-badge-unevaluated-list";
import CohortStudentModuleBadgeResubmitList from "./-list/-cohort-student-module-badge-resubmit-list/cohort-student-module-badge-resubmit-list";
import { getConfigurationSettings } from "../../service/utils-service";
import { ConfigurationSettings } from "../../model/configuration-settings";
import CohortStudentAverageRatingList from "./-list/-cohort-student-average-rating-list/cohort-student-average-rating-list";

export const MANAGER_DASHBOARD_TAB_STATE: string = "MANAGER_DASHBOARD_TAB_STATE";
export const MANAGER_DASHBOARD_TAB_STATE_STUDENT_RATING: string = "MANAGER_DASHBOARD_TAB_STATE_STUDENT_RATING";
export const MANAGER_DASHBOARD_TAB_STATE_MODULE_RATING: string = "MANAGER_DASHBOARD_TAB_STATE_MODULE_RATING";
export const MANAGER_DASHBOARD_TAB_STATE_BADGE_RATING: string = "MANAGER_DASHBOARD_TAB_STATE_BADGE_RATING";
export const MANAGER_DASHBOARD_TAB_STATE_UNSUBMITTED: string = "MANAGER_DASHBOARD_TAB_STATE_UNSUBMITTED";
export const MANAGER_DASHBOARD_TAB_STATE_UNEVALUATED: string = "MANAGER_DASHBOARD_TAB_STATE_UNEVALUATED";
export const MANAGER_DASHBOARD_TAB_STATE_RESUBMIT: string = "MANAGER_DASHBOARD_TAB_STATE_RESUBMIT";

export default function ManagerDashboardView() {
    const [configurationSettings, setConfigurationSettings] = useState<ConfigurationSettings | undefined>(undefined);
    const [tab, setTab] = useState<string>(localStorage.getItem(MANAGER_DASHBOARD_TAB_STATE) ?? MANAGER_DASHBOARD_TAB_STATE_MODULE_RATING);
    const [filter, setFilter] = useState<DashboardManagerFilter>({})

    useEffect(() => {
        getConfigurationSettings().then(result => {
            setConfigurationSettings(result ?? []);
        });
    }, []);

    const changeTab = (newTab: string) => {
        localStorage.setItem(MANAGER_DASHBOARD_TAB_STATE, newTab);
        setTab(newTab);
    }

    return (
        <React.Fragment>
            <div className={commonStyles.content} >
                <DashboardManagerFilterHander 
                    filter={filter} 
                    setFilter={setFilter} 
                    moduleDisabled={tab === MANAGER_DASHBOARD_TAB_STATE_STUDENT_RATING}
                />
            </div>
            {filter.cohortId !== undefined && <TabContext value={tab}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={(_: React.SyntheticEvent, newTab: string) => changeTab(newTab)}>
                        <Tab label="Student rating" value={MANAGER_DASHBOARD_TAB_STATE_STUDENT_RATING} />
                        <Tab label="Module rating" value={MANAGER_DASHBOARD_TAB_STATE_MODULE_RATING} />
                        <Tab label="Badge rating" value={MANAGER_DASHBOARD_TAB_STATE_BADGE_RATING} />
                        <Tab label="Unsubmitted" value={MANAGER_DASHBOARD_TAB_STATE_UNSUBMITTED} />
                        <Tab label="Unevaluated" value={MANAGER_DASHBOARD_TAB_STATE_UNEVALUATED} />
                        <Tab label="Resubmit" value={MANAGER_DASHBOARD_TAB_STATE_RESUBMIT} />
                    </TabList>
                </Box>
                <TabPanel value={MANAGER_DASHBOARD_TAB_STATE_STUDENT_RATING}>
                    <CohortStudentAverageRatingList
                        filter={filter}
                        setFilter={setFilter}
                    />
                </TabPanel>
                <TabPanel value={MANAGER_DASHBOARD_TAB_STATE_MODULE_RATING}>
                    <CohortStudentModuleAverageRatingList
                        filter={filter}
                        setFilter={setFilter}
                    />
                </TabPanel>
                <TabPanel value={MANAGER_DASHBOARD_TAB_STATE_BADGE_RATING}>
                    <CohortStudentModuleBadgeRatingList
                        filter={filter}
                        setFilter={setFilter}
                    />
                </TabPanel>
                <TabPanel value={MANAGER_DASHBOARD_TAB_STATE_UNSUBMITTED}>
                    <CohortStudentModuleBadgeUnsubmittedList
                        filter={filter}
                        setFilter={setFilter}
                        expectedSubmissionDateOffsetHours={configurationSettings?.expectedSubmissionDateOffsetHours ?? 0}
                    />
                </TabPanel>
                <TabPanel value={MANAGER_DASHBOARD_TAB_STATE_UNEVALUATED}>
                    <CohortStudentModuleBadgeUnevaluatedList
                        filter={filter}
                        setFilter={setFilter}
                        expectedEvaluationDateOffsetHours={configurationSettings?.expectedEvaluationDateOffsetHours ?? 0}
                    />
                </TabPanel>
                <TabPanel value={MANAGER_DASHBOARD_TAB_STATE_RESUBMIT}>
                    <CohortStudentModuleBadgeResubmitList
                        filter={filter}
                        setFilter={setFilter}
                    />
                </TabPanel>
            </TabContext>}
        </React.Fragment>
    );
}
