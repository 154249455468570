import { FieldDefinition } from "../../../../component/form/field-definition";
import StarIcon from '@mui/icons-material/Star';

export const evaluatorReviewFieldDefinitions: FieldDefinition[] = [
    {
        id: "badgeInstruction",
        name: "Badge instruction",
        type: "info",
        required: false,
        disabled: false,
        fullInput: true
    },
    {
        id: "badgeAcceptanceCriteria",
        name: "Badge acceptance criteria",
        type: "info",
        required: false,
        disabled: false,
        fullInput: true
    },
    {
        id: "workingBadgePath",
        name: "Badge path",
        type: "text",
        required: false,
        disabled: true,
        fullInput: true
    },
    {
        id: "rating",
        name: "Rating",
        type: "number-select",
        required: false,
        disabled: false,
        min: 0,
        max: 3,
        icon: <StarIcon color="primary" />
    },
    {
        id: "comment",
        name: "Comment",
        type: "text",
        required: false,
        disabled: false
    }
];