import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { useAuth } from "../context/auth/auth-provider";
import styles from "./style/home.module.css";
import commonStyles from "./style/common.module.css";
import { ROUTE_CATEGORY_DASHBOARD_TITLE, ROUTE_CATEGORY_EDUCATION_MANAGMENT_TITLE, ROUTE_SETUP, getAccessibleRoutes } from "../infrastructure/route-setup";
import { hasCategoryAccess, hasRouteAccess } from "../infrastructure/route-access";
import { RouteCategory } from "../infrastructure/route-category";

const ROUTE_CATEGORY_OTHER_TITLE = "Other"

export default function Home() {
    const [loading, setLoading] = useState(true);

    const auth = useAuth();
    const navigate = useNavigate();

    const accessibleRoutes = getAccessibleRoutes(auth.user?.roles ?? []);

    useEffect(() => {
        if (accessibleRoutes.length === 1) {
            navigate(accessibleRoutes[0].paths[0]);
        } else {
            setLoading(false);
        }
    }, [accessibleRoutes, navigate]);

    const getHomeRouteSetup = () => {
        const homeRouteSetup = [] as RouteCategory[];
        homeRouteSetup.push({ title: ROUTE_CATEGORY_DASHBOARD_TITLE, routes: [] })
        homeRouteSetup.push({ title: ROUTE_CATEGORY_EDUCATION_MANAGMENT_TITLE, routes: [] })
        homeRouteSetup.push({ title: ROUTE_CATEGORY_OTHER_TITLE, routes: [] })

        ROUTE_SETUP.forEach(_ => {
            let route = homeRouteSetup.find(hr => hr.title === _.title);
            if (route === undefined) {
                route = homeRouteSetup.find(hr => hr.title === ROUTE_CATEGORY_OTHER_TITLE);
            }

            route!.routes = route!.routes.concat(_.routes);
        });

        return homeRouteSetup
    }

    return (
        <React.Fragment>
            {loading === false && <div className={commonStyles.content}>
                <h1>Welcome {auth.user?.name}!</h1>
                <span>To Learning Management System (LMS), here you can access and manage all aspect of Learning Lions learning platform and its content.</span>
            </div>}
            {loading === false && accessibleRoutes.length === 0 && <div className={commonStyles.content}>
                <hr />
                <span>It looks like you don't have access to any of the features in LMS. Speak to the <b>Administrator</b> so that you have to correct Role(s).</span>
            </div>}
            {loading === false && accessibleRoutes.length > 0 && <div>
                {getHomeRouteSetup().filter(_ => hasCategoryAccess(_, auth.user?.roles!)).map(category => (
                    <div key={category.title} className={commonStyles.content}>
                        <h2>{category.title}</h2>
                        <div key={category.title} className={styles.cardContent}>
                            {category.routes
                                .filter(route => accessibleRoutes.length > 1 || route.paths.find(_ => _ === "/") === undefined)
                                .filter(route => route.menu && hasRouteAccess(route.roles, auth.user?.roles!))
                                .filter(route => accessibleRoutes.find(_ => _.paths[0] === route.paths[0]) !== undefined)
                                .map(route =>
                                    route.paths.map(path => (
                                        <Card sx={{ width: 300 }} key={path} >
                                            <CardActionArea>
                                                <CardContent onClick={() => navigate(path)} >
                                                    <div className={styles.cardTitle}>
                                                        <span className={styles.cardIcon} >{route.icon}</span>
                                                        <span>{route.name}</span>
                                                    </div>
                                                    <Typography variant="body2" color="text.secondary">
                                                        {route.description}
                                                    </Typography>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    )
                                ))
                            }
                        </div>
                    </div>
                ))}
            </div>}
        </React.Fragment>
    );
}
