import { User } from "../model/user";
import { requestOptions, HttpMethod } from "../../../utils/api/request-options";
import { handleResponse, handleResponseNoContent } from "../../../utils/api/response-handler";

export function getUserStudents(cohortId?: number | undefined): Promise<User[]> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/student/actor/list?cohortId=${cohortId ?? ''}`, requestOptions(HttpMethod.GET))
        .then(response => {
            return handleResponse<User[]>(response)
        });
}

export function getUserStudent(studentUserId: number): Promise<User> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/student/actor/${studentUserId}`, requestOptions(HttpMethod.GET))
        .then(response => {
            return handleResponse<User>(response)
        });
}

export function addUserStudent(user: User): Promise<User> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/student/actor`, requestOptions(HttpMethod.POST, user))
        .then(response => {
            return handleResponse<User>(response)
        });
}

export function updateUserStudent(user: User): Promise<User> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/student/actor`, requestOptions(HttpMethod.PUT, user))
        .then(response => {
            return handleResponse<User>(response)
        });
}

export function deleteUserStudent(userId: number): Promise<void> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/student/actor/${userId}`, requestOptions(HttpMethod.DELETE))
        .then(response => {
            return handleResponseNoContent(response)
        });
}
