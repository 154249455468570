import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Badge } from "../../model/badge";
import { getBadge } from "../../service/badge-service";
import BadgeHandler from "./-badge-handler/badge-handler";
import ViewLoader from "../../../../component/misc/view-loader";
import { ASSIGNMENT_MANAGEMENT, EDUCATION_MANAGEMENT_MODULE_BADGE_EDIT, PARAMETER_BADGE_ID, PARAMETER_MODULE_ID } from "../../../../infrastructure/route";
import { useCache } from "../../../../context/cache/cache-provider";
import { getModule } from "../../service/module-service";
import { Module } from "../../model/module";
import AssignmentList from "../../../-assignment/-assignment-management/-assignment-list/assignment-list";
import { useAuth } from "../../../../context/auth/auth-provider";

export const BADGE_TAB_STATE: string = "BADGE_TAB_STATE";
export const BADGE_TAB_STATE_ADD_EDIT: string = "BADGE_TAB_STATE_ADD_EDIT";
export const BADGE_TAB_STATE_ASSIGNMENTS: string = "BADGE_TAB_STATE_ASSIGNMENTS";

export default function BadgeView() {
    const { moduleId, badgeId } = useParams();
    const newModel = badgeId === undefined;

    const location = useLocation();
    const badges = (location.state !== null ? location.state : []) as Badge[];

    const [tab, setTab] = useState<string>(localStorage.getItem(BADGE_TAB_STATE) ?? BADGE_TAB_STATE_ADD_EDIT);
    const [loading, setLoading] = useState<boolean>(true);
    const [module, setModule] = useState<Module>();
    const [badge, setBadge] = useState<Badge>({
        moduleId: moduleId ?? "",
        badgeId: badgeId ?? "",
        title: "",
        instruction: "",
        badgeFolder: "",
        active: true,
        sequence: badges.length === 0 ? 1 : Math.max(...badges.map(_ => _.sequence)) + 1,
        estimatedTime: 1,
        allowResubmit: false
    });

    const cache = useCache();
    const auth = useAuth();

    useEffect(() => {
        cache.update(
            { key: PARAMETER_BADGE_ID, value: badge.title },
            { key: PARAMETER_MODULE_ID, value: module?.title ?? '' }
        );
    }, [cache, badge.title, module?.title]);

    useEffect(() => {
        if (!newModel) {
            setLoading(true);

            const getBadgeCall = getBadge(badgeId);
            const getModuleCall = getModule(moduleId!);

            Promise.all([getBadgeCall, getModuleCall]).then(result => {
                setBadge(result[0]);
                setModule(result[1]);
                setLoading(false);
            });
        } else {
            getModule(moduleId!).then(result => {
                setModule(result);
                setLoading(false);
            });
        }
    }, [badgeId, moduleId, newModel]);

    const changeTab = (newTab: string) => {
        localStorage.setItem(BADGE_TAB_STATE, newTab);
        setTab(newTab);
    }

    return (
        <React.Fragment>
            <ViewLoader loading={loading} />
            {loading === false && <TabContext value={tab}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={(_: React.SyntheticEvent, newTab: string) => changeTab(newTab)}>
                        <Tab label={newModel === true ? "Add" : "Edit"} value={BADGE_TAB_STATE_ADD_EDIT} />
                        {!newModel && auth.hasRouteAccess(ASSIGNMENT_MANAGEMENT) && <Tab label="Assignments" value={BADGE_TAB_STATE_ASSIGNMENTS} />}
                    </TabList>
                </Box>
                <TabPanel value={BADGE_TAB_STATE_ADD_EDIT}>
                    <BadgeHandler
                        newModel={newModel}
                        badge={badge}
                        setBadge={setBadge}
                    />
                </TabPanel>
                <TabPanel value={BADGE_TAB_STATE_ASSIGNMENTS}>
                    <AssignmentList 
                        badgeId={badge.badgeId}
                        originUrl={`${EDUCATION_MANAGEMENT_MODULE_BADGE_EDIT
                            .replace(PARAMETER_MODULE_ID, moduleId ?? "")
                            .replace(PARAMETER_BADGE_ID, badgeId ?? "")}`}
                    />
                </TabPanel>
            </TabContext>}
        </React.Fragment>
    );
}
