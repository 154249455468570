import React, { useEffect, useState } from "react";
import commonStyles from "../../../style/common.module.css";
import { Evaluator, evaluatorDisplayName } from "../../model/evaluator";
import { Module } from "../../model/module";
import { getEvaluators } from "../../service/evaluator-service";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../context/auth/auth-provider";
import { EDUCATION_MANAGEMENT_EVALUATOR_MODULE_ASSIGN, PARAMETER_EVALUATOR_ID } from "../../../../infrastructure/route";
import ViewLoader from "../../../../component/misc/view-loader";

export default function ModuleEvaluatiorList({ module }: { module: Module }) {
    const [loading, setLoading] = useState(true);
    const [evaluators, setEvaluators] = useState<Evaluator[]>([]);

    const navigate = useNavigate();
    const auth = useAuth();

    useEffect(() => {
        setLoading(true);
        getEvaluators(module.moduleId).then(result => {
            setEvaluators(result ?? []);
            setLoading(false);
        });
    }, [module]);

    const openEvaluator = (evaluatorId: number) => {
        if (auth.hasRouteAccess(EDUCATION_MANAGEMENT_EVALUATOR_MODULE_ASSIGN)) {
            navigate(EDUCATION_MANAGEMENT_EVALUATOR_MODULE_ASSIGN.replace(PARAMETER_EVALUATOR_ID, evaluatorId.toString()));
        }
    }

    return (
        <React.Fragment>
            <ViewLoader loading={loading} />
            {loading === false && <div>
                <h4>Selected evaluator(s)</h4>
                {evaluators.length === 0 && <p><i>No evaluators</i></p>}
                {evaluators.length > 0 && evaluators.map(evaluator => {
                    return <p
                        key={evaluator.evaluatorId}
                        className={auth.hasRouteAccess(EDUCATION_MANAGEMENT_EVALUATOR_MODULE_ASSIGN) ? commonStyles.clickable : ''}
                        onClick={() => openEvaluator(evaluator.evaluatorId!)} >
                        {evaluatorDisplayName(evaluator)}
                    </p>
                })}
            </div>}
        </React.Fragment>
    );
}
