import { useEffect, useState } from 'react';
import commonStyles from "../../../style/common.module.css";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import StarIcon from '@mui/icons-material/Star';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CloseIcon from '@mui/icons-material/Close';
import { ModuleAssignment } from '../../model/module-assignment';
import { BadgeAssignment } from '../../model/badge-assignment';
import { LoadingButton } from '@mui/lab';
import { useDialog } from '../../../../context/dialog/dialog-provider';
import { DialogType, informationText } from '../../../../context/dialog/dialog-context-type';
import { getNumber } from '../../../../utils/helper/string-helper';
import StudentJournalBadgeContent from './student-journal-badge-content';
import { AssignmentStatus } from '../../enum/assignment-status';
import { useSearchParams } from 'react-router-dom';
import { 
    ASSIGNMENT_QUERY_PARAMETER_MODULEID, 
    ASSIGNMENT_QUERY_PARAMETER_TAB_VALUE_JOURNAL, 
    ASSIGNMENT_QUERY_PARAMETER_WORKITEMID 
} from '../../utils/assignment-constants';
import { getBadgeTerminology, getModuleCourseLevel, getModuleTerminology, getGeneralCourseLevel } from '../../utils/terminology';
import { CourseLevel } from '../../../-education/enum/course-level';
import { updateSearchParams } from '../../utils/assignment-navigation';

export default function StudentJournal({
    generalCourseLevel,
    submittedModuleAssignments,
    updateModuleAssignments
}: {
        generalCourseLevel: CourseLevel,
        submittedModuleAssignments: ModuleAssignment[],
        updateModuleAssignments(badgeAssignment: BadgeAssignment): void
}) {
    const [searchParams, setSearchParams] = useSearchParams();
    const moduleId = searchParams.get(ASSIGNMENT_QUERY_PARAMETER_MODULEID);
    const workItemId = Number(searchParams.get(ASSIGNMENT_QUERY_PARAMETER_WORKITEMID));
    const [submittedBadgeAssignment, setSubmittedBadgeAssignment] = useState<BadgeAssignment>(submittedModuleAssignments.find(_ => _.moduleId === moduleId)?.badges.find(_ => _.workItemId === workItemId) ?? {});
    
    const dialog = useDialog();

    const studentCourseLevel = submittedModuleAssignments.length > 0 ? getGeneralCourseLevel(submittedModuleAssignments) : generalCourseLevel;

    useEffect(() => {
        updateSearchParams(searchParams, setSearchParams, submittedBadgeAssignment, ASSIGNMENT_QUERY_PARAMETER_TAB_VALUE_JOURNAL);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submittedBadgeAssignment]);

    const getBadges = () => {
        const module = submittedModuleAssignments.find(_ => _.moduleId === submittedBadgeAssignment.moduleId);
        return module?.badges.filter(_ => _.submissionDate !== undefined || _.evaluatedDate !== undefined) ?? [] as BadgeAssignment[];
    }

    const handleBadgeSelect = (badgeAssignment: BadgeAssignment) => {
        if (badgeAssignment.workItemId !== submittedBadgeAssignment.workItemId) {
            setSubmittedBadgeAssignment(badgeAssignment);
        } else {
            setSubmittedBadgeAssignment({ ...submittedBadgeAssignment, workItemId: undefined });
        }
    }

    const openHelpInformationDialog = () => {
        dialog.openDialog(DialogType.INFORMATION, "Help / Information", [
            informationText(`In the list of <b>
                ${getBadgeTerminology(studentCourseLevel, true)} submissions</b>, you can see the <b>rating</b> from the evaluator. It will be empty until the evaluator looks at your work and leaves a rating, or a comment.`),
            informationText(`The evaluator can review <b>
                ${getBadgeTerminology(studentCourseLevel, true)} submission</b> with a <b>comment and no rating</b>, informing you about something. A comment icon will indicate this.`),
            informationText(`After you opened a <b>
                ${getBadgeTerminology(studentCourseLevel, true)} submission</b> then you can <b>Edit</b> or <b>Undo submission</b> it. To do so, click on the menu in the top right corner of the 
                ${getBadgeTerminology(studentCourseLevel, true)} submission, there you will find the options. These options are only possible until the 
                ${getBadgeTerminology(studentCourseLevel, true)} submission is <b>rated</b>.`)
        ]);
    }

    return (
        <div className={commonStyles.pb} >
            {submittedModuleAssignments.length > 0 && <FormControl id={`module-form-control`} sx={{ flex: '1 1 100%', alignSelf: 'flex-start', width: '100%', paddingBottom: '1rem' }}>
                <InputLabel id={`module-label`}>{getModuleTerminology(getModuleCourseLevel(submittedModuleAssignments, submittedBadgeAssignment.badgeId))}</InputLabel>
                <Select
                    labelId={`module-label`}
                    id={`module-select`}
                    value={submittedBadgeAssignment.moduleId ?? ''}
                    label={`${getModuleTerminology(getModuleCourseLevel(submittedModuleAssignments, submittedBadgeAssignment.badgeId))}`}
                    onChange={e => setSubmittedBadgeAssignment({
                        ...submittedBadgeAssignment,
                        moduleId: e.target.value,
                        workItemId: undefined
                    })}
                >
                    {submittedModuleAssignments.map(_ => {
                        return <MenuItem key={_.moduleId} id={`${_}-menu-item`} value={_.moduleId}>
                            {_.moduleName}
                        </MenuItem>
                    })}
                </Select>
            </FormControl>}
            {submittedModuleAssignments.length === 0 && <div>
                <span>Your <b>Journal</b> is empty, when you have submitted an {getBadgeTerminology(studentCourseLevel, true)} then you can view, and futher handle, it from here.</span>
            </div>}
            {submittedBadgeAssignment.moduleId !== undefined && <div className={commonStyles.mb}>
                {getBadges().map(_ => {
                    return (<Accordion key={_.workItemId} expanded={submittedBadgeAssignment.workItemId === _.workItemId} onChange={() => handleBadgeSelect(_)}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography sx={{ width: '99%', flexShrink: 0, }}>
                                {_.badgeName}
                                {getNumber(_.rating) > 0 && Array.from({ length: getNumber(_.rating) }, () => <StarIcon fontSize="small" key={`${Math.random()}-select-icon`} color="primary" sx={{ float: 'right' }} />)}
                                {_.assignmentStatus === AssignmentStatus.Evaluated_Commented && <ChatBubbleIcon color="primary" sx={{ float: 'right' }} />}
                                {_.assignmentStatus === AssignmentStatus.Dismissed && <CloseIcon color="primary" sx={{ float: 'right' }} />}
                            </Typography>
                        </AccordionSummary>
                        {submittedBadgeAssignment.workItemId === _.workItemId && <AccordionDetails>
                            <StudentJournalBadgeContent
                                initialBadge={submittedBadgeAssignment}
                                updateBadge={(badge) => {
                                    updateModuleAssignments(badge);
                                    setSubmittedBadgeAssignment(badge);
                                }}
                                removeBadge={(badge) => {
                                    badge.workItemId = undefined;
                                    badge.submissionDate = undefined;
                                    badge.evaluatedDate = undefined;
                                    badge.comment = undefined;
                                    badge.workingBadgePath = undefined;
                                    if (getBadges().length === 0) {
                                        setSubmittedBadgeAssignment({});
                                    }
                                    updateModuleAssignments(badge);
                                }}
                                moduleCourseLevel={getModuleCourseLevel(submittedModuleAssignments, submittedBadgeAssignment.badgeId)}
                            />
                        </AccordionDetails>}
                    </Accordion>)
                })}
            </div>}
            {submittedModuleAssignments.length > 0 && <div style={{ display: 'flex', justifyContent: 'end' }}>
                <LoadingButton
                    id={`info-button`}
                    loadingPosition="start"
                    startIcon={<HelpOutlineIcon />}
                    variant="outlined"
                    onClick={() => openHelpInformationDialog()}
                >
                    Info / Help
                </LoadingButton>
            </div>}
        </div>
    );
}