import { SetURLSearchParams } from "react-router-dom";
import { BadgeAssignment } from "../model/badge-assignment";
import { 
    ASSIGNMENT_QUERY_PARAMETER_BADGEID,
    ASSIGNMENT_QUERY_PARAMETER_MODULEID,
    ASSIGNMENT_QUERY_PARAMETER_TAB,
    ASSIGNMENT_QUERY_PARAMETER_WORKITEMID
} from "./assignment-constants";

const CURRENT_BADGE_ASSIGNMENT = "CURRENT_BADGE_ASSIGNMENT";

export const updateSearchParams = (
    searchParams: URLSearchParams,
    setSerachParams: SetURLSearchParams,
    badgeAssignment: BadgeAssignment,
    currentTab: string
) => {
    const currentbadgeAssignmentId = localStorage.getItem(CURRENT_BADGE_ASSIGNMENT);
    const badgeAssignmentId = `M${badgeAssignment.moduleId ?? ""}:B${badgeAssignment.badgeId ?? ""}:W${badgeAssignment.workItemId ?? ""}`;

    if(searchParams.get(ASSIGNMENT_QUERY_PARAMETER_TAB) !== currentTab || currentbadgeAssignmentId !== badgeAssignmentId) {
        
        searchParams.set(ASSIGNMENT_QUERY_PARAMETER_TAB, currentTab);
        searchParams.set(ASSIGNMENT_QUERY_PARAMETER_MODULEID, badgeAssignment.moduleId ?? "");
        searchParams.set(ASSIGNMENT_QUERY_PARAMETER_BADGEID, badgeAssignment.badgeId ?? "");

        if(badgeAssignment.workItemId !== undefined) {
            searchParams.set(ASSIGNMENT_QUERY_PARAMETER_WORKITEMID, badgeAssignment.workItemId?.toString() ?? "");
            searchParams.delete(ASSIGNMENT_QUERY_PARAMETER_BADGEID);
        } else {
            searchParams.set(ASSIGNMENT_QUERY_PARAMETER_BADGEID, badgeAssignment.badgeId ?? "");
            searchParams.delete(ASSIGNMENT_QUERY_PARAMETER_WORKITEMID);
        }
        
        localStorage.setItem(CURRENT_BADGE_ASSIGNMENT, badgeAssignmentId);
        setSerachParams(searchParams);
    }
}
