import React, { useEffect, useState } from "react";
import commonStyles from "../../style/common.module.css";
import { getEvaluatorAssignments } from "../service/assignment-service";
import { ModuleAssignment } from "../model/module-assignment";
import { BadgeAssignment } from "../model/badge-assignment";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, LinearProgress, Tab } from "@mui/material";
import { useAuth } from "../../../context/auth/auth-provider";
import EvaluatorReview from "./-evaluator-review/evaluator-review";
import EvaluatorJournal from "./-evaluator-journal/evaluator-journal";
import { getConfigurationSettings } from "../../service/utils-service";
import { useSearchParams } from "react-router-dom";
import {
    ASSIGNMENT_QUERY_PARAMETER_TAB,
    ASSIGNMENT_QUERY_PARAMETER_TAB_VALUE_EVALUATOR_REVIEW,
    ASSIGNMENT_QUERY_PARAMETER_TAB_VALUE_JOURNAL
} from "../utils/assignment-constants";
import { getBadgeTerminology, getGeneralCourseLevel, getStudentTerminology } from "../utils/terminology";
import { ConfigurationSettings } from "../../model/configuration-settings";

export default function EvaluatorHome() {
    const [searchParams] = useSearchParams();
    const [tab, setTab] = useState<string>(searchParams.get(ASSIGNMENT_QUERY_PARAMETER_TAB) ?? ASSIGNMENT_QUERY_PARAMETER_TAB_VALUE_EVALUATOR_REVIEW);
    const [loading, setLoading] = useState(true);
    const [moduleAssignments, setModuleAssignments] = useState<ModuleAssignment[]>([]);
    const [configurationSettings, setConfigurationSettings] = useState<ConfigurationSettings | undefined>();

    const auth = useAuth();

    const generalCourseLevel = getGeneralCourseLevel(moduleAssignments);

    useEffect(() => {
        setLoading(true);

        const getConfigurationSettingsCall = getConfigurationSettings();
        const getEvaluatorAssignmentsCall = getEvaluatorAssignments();

        Promise.all([getConfigurationSettingsCall, getEvaluatorAssignmentsCall]).then((result) => {
            if (result[1] !== undefined) {
                result[1].forEach(_ => _.badges.forEach(b => b.moduleId = _.moduleId));
                setModuleAssignments(result[1]);
            }
            
            setConfigurationSettings(result[0])
            setModuleAssignments(result[1] ?? []);

            setLoading(false);
        }).catch(() => {
            setLoading(false);
        });
    }, []);

    const updateModuleAssignments = (badgeAssignment: BadgeAssignment) => {
        var updateModuleAssignments = [...moduleAssignments!];
        var module = updateModuleAssignments.find(_ => _.badges.filter(b => b.workItemId === badgeAssignment.workItemId).length > 0);
        var badge = module?.badges.find(_ => _.workItemId === badgeAssignment.workItemId);

        if (module !== undefined && badge !== undefined) {
            let bargeIndex = module?.badges.indexOf(badge);
            let moduleIndex = updateModuleAssignments.indexOf(module);

            module.badges[bargeIndex] = badgeAssignment;
            updateModuleAssignments[moduleIndex] = module;
            
            setModuleAssignments(updateModuleAssignments);
        }
    }

    const removeBadgeAssignment = (workItemId: number) => {
        let updateModuleAssignments = [...moduleAssignments];

        const moduleAssignment = updateModuleAssignments.find(m => m.badges.find(b => b.workItemId === workItemId) !== undefined);
        const badgeAssignment = moduleAssignment?.badges.find(b => b.workItemId === workItemId);

        if(moduleAssignment !== undefined && badgeAssignment !== undefined) {
            const badgeAssignmentIndex = moduleAssignment.badges.indexOf(badgeAssignment);
            moduleAssignment.badges.splice(badgeAssignmentIndex, 1);

            if (moduleAssignment.badges.length === 0) {
                const moduleAssignmentIndex = updateModuleAssignments.indexOf(moduleAssignment);
                updateModuleAssignments.splice(moduleAssignmentIndex, 1);
            }

            setModuleAssignments(updateModuleAssignments);
        }
    }

    return (
        <React.Fragment>
            <div className={commonStyles.content}>
                <h3>Welcome {auth.user?.name}!</h3>
                <span>Happy to see you :) Are you ready to <b>Review</b> some submitted {getBadgeTerminology(generalCourseLevel, true, true)} from our {getStudentTerminology(generalCourseLevel, true, true)}?
                </span>
            </div>
            {loading === true && <LinearProgress />}
            {loading === false && <TabContext value={tab} >
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={(_: React.SyntheticEvent, newTab: string) => setTab(newTab)}>
                        <Tab label="Review" value={ASSIGNMENT_QUERY_PARAMETER_TAB_VALUE_EVALUATOR_REVIEW} />
                        <Tab label="Journal" value={ASSIGNMENT_QUERY_PARAMETER_TAB_VALUE_JOURNAL} />
                    </TabList>
                </Box>
                <TabPanel value={ASSIGNMENT_QUERY_PARAMETER_TAB_VALUE_EVALUATOR_REVIEW} >
                    <EvaluatorReview
                        generalCourseLevel={generalCourseLevel}
                        unreviewedModuleAssignments={moduleAssignments.filter(_ => _.badges.filter(b => b.submissionDate !== undefined && b.evaluatedDate === undefined).length >= 1)}
                        updateModuleAssignments={updateModuleAssignments}
                        removeBadgeAssignment={removeBadgeAssignment}
                    />
                </TabPanel>
                <TabPanel value={ASSIGNMENT_QUERY_PARAMETER_TAB_VALUE_JOURNAL} >
                    <EvaluatorJournal
                        generalCourseLevel={generalCourseLevel}
                        reviewedModuleAssignments={moduleAssignments.filter(_ => _.badges.filter(b => b.submissionDate !== undefined && b.evaluatedDate !== undefined).length >= 1) ?? []}
                        updateModuleAssignments={updateModuleAssignments}
                        editHourLimit={configurationSettings?.updateEvaluatorReviewHoursLimit ?? 0}
                        assignmentDaysBack={configurationSettings?.evaluatorAssignmentDaysBack ?? 0}
                    />
                </TabPanel>
            </TabContext>}
        </React.Fragment>
    );
}
