import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import ViewLoader from "../../../component/misc/view-loader";
import { useCache } from "../../../context/cache/cache-provider";
import { PARAMETER_WORK_ITEM_ID } from "../../../infrastructure/route";
import { WorkItemAssignmentDetalis } from "../model/work-item-assignment-details";
import { getWorkItemAssignment } from "../service/assignment-service";
import AssignmentHandler from "./-assignment-handler/assignment-handler";
import { decodeUrl } from "../../../utils/helper/string-helper";

export const ASSIGNMENT_MANAGMENT_TAB_STATE: string = "ASSIGNMENT_MANAGMENT_TAB_STATE";
export const ASSIGNMENT_MANAGMENT_TAB_STATE_EDIT: string = "ASSIGNMENT_MANAGMENT_TAB_STATE_EDIT";

export default function AssignmentManagementView() {
    const { workItemId, encodedOriginUrl } = useParams();

    const [tab, setTab] = useState<string>(localStorage.getItem(ASSIGNMENT_MANAGMENT_TAB_STATE) ?? ASSIGNMENT_MANAGMENT_TAB_STATE_EDIT);
    const [loading, setLoading] = useState(true);
    const [workItemAssignmentDetalis, setWorkItemAssignmentDetalis] = useState<WorkItemAssignmentDetalis | undefined>();

    const cache = useCache();

    useEffect(() => {
        if(workItemAssignmentDetalis?.badgeName !== undefined) {
            cache.update({ key: PARAMETER_WORK_ITEM_ID, value: workItemAssignmentDetalis.badgeName });
        }
    }, [cache, workItemAssignmentDetalis?.badgeName]);

    useEffect(() => {
        setLoading(true);
        getWorkItemAssignment(Number(workItemId)).then(result => {
            result.newEvaluatorId = result.evaluatorId;
            setWorkItemAssignmentDetalis(result);
            setLoading(false);
        })
    }, [workItemId]);

    const changeTab = (newTab: string) => {
        localStorage.setItem(ASSIGNMENT_MANAGMENT_TAB_STATE, newTab);
        setTab(newTab);
    }

    return (
        <React.Fragment>
            <ViewLoader loading={loading} />
            {loading === false && <TabContext value={tab} >
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={(_: React.SyntheticEvent, newTab: string) => changeTab(newTab)}>
                        <Tab label={"Edit"} value={ASSIGNMENT_MANAGMENT_TAB_STATE_EDIT} />
                    </TabList>
                </Box>
                {workItemAssignmentDetalis !== undefined && <TabPanel value={ASSIGNMENT_MANAGMENT_TAB_STATE_EDIT}>
                    <AssignmentHandler
                        originUrl={decodeUrl(encodedOriginUrl)}
                        workItemAssignmentDetalis={workItemAssignmentDetalis}
                        setWorkItemAssignmentDetalis={setWorkItemAssignmentDetalis}
                    />
                </TabPanel>}
            </TabContext>}
        </React.Fragment>
    );
}
