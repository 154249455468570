import SchoolIcon from '@mui/icons-material/School';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import Diversity1Icon from '@mui/icons-material/Diversity1';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import HomeIcon from '@mui/icons-material/Home';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined';
import DashboardIcon from '@mui/icons-material/Dashboard';
import BeenhereOutlinedIcon from '@mui/icons-material/BeenhereOutlined';
import BarChartIcon from '@mui/icons-material/BarChart';
import RuleIcon from '@mui/icons-material/Rule';
import {
    HOME,
    ACCOUNT_MANAGEMENT_USER_ADD,
    ACCOUNT_MANAGEMENT_USER_EDIT,
    ACCOUNT_MANAGEMENT_USER_LIST,
    EDUCATION_MANAGEMENT_MODULE_ADD,
    EDUCATION_MANAGEMENT_MODULE_BADGE_ADD,
    EDUCATION_MANAGEMENT_MODULE_BADGE_EDIT,
    EDUCATION_MANAGEMENT_MODULE_EDIT,
    EDUCATION_MANAGEMENT_MODULE_LIST,
    EDUCATION_MANAGEMENT_COHORT_LIST,
    EDUCATION_MANAGEMENT_COHORT_ADD,
    EDUCATION_MANAGEMENT_COHORT_EDIT,
    EDUCATION_PRESENTATION_ADD,
    EDUCATION_PRESENTATION_EDIT,
    EDUCATION_PRESENTATION_LIST,
    EDUCATION_ATTENDANCE_LIST,
    EDUCATION_ATTENDANCE_UPDATE,
    EDUCATION_MANAGEMENT_EVALUATOR_LIST,
    EDUCATION_MANAGEMENT_EVALUATOR_ADD,
    EDUCATION_MANAGEMENT_EVALUATOR_MODULE_ASSIGN,
    WORK_MY_ASSIGNMENTS,
    WORK_MY_REVIEWS,
    EXAMINE_SURVEY_SUBMIT_LIST,
    EXAMINE_SURVEY_SUBMIT_VIEW,
    EXAMINE_SURVEY_HANDLE_LIST,
    EXAMINE_SURVEY_HANDLE_ADD,
    EXAMINE_SURVEY_HANDLE_EDIT,
    EXAMINE_SURVEY_RESPONDENT_LIST,
    EXAMINE_SURVEY_RESPONDENT_VIEW,
    STATISTIC_AVERAGE_RATING_MODULE_LIST,
    STATISTIC_AVERAGE_RATING_BADGE_LIST,
    DASHBOARD_MANAGER,
    ACCOUNT_MANAGEMENT_STUDENT_ADD,
    ACCOUNT_MANAGEMENT_STUDENT_EDIT,
    ACCOUNT_MANAGEMENT_STUDENT_LIST,
    ASSIGNMENT_MANAGEMENT
} from "./route";
import { RouteCategory } from './route-category';
import Home from '../section/home';
import ModuleList from '../section/-education/-module/-module-list/module-list';
import ModuleView from '../section/-education/-module/module-view';
import CohortList from '../section/-education/-cohort/-cohort-list/cohort-list';
import CohortView from '../section/-education/-cohort/cohort-view';
import EvaluatorList from '../section/-education/-evaluator/-evaluator-list/evaluator-list';
import PresentationList from '../section/-education/-presentation/-presentation-list/presentation-list';
import UserList from '../section/-account/-user/-user-list/user-list';
import StudentHome from '../section/-assignment/-student/student-home';
import EvaluatorHome from '../section/-assignment/-evaluator/evaluator-home';
import BadgeView from '../section/-education/-module/-module-badges/badge-view';
import EvaluatorView from '../section/-education/-evaluator/evaluator-view';
import PresentationView from '../section/-education/-presentation/presentation-view';
import UserView from '../section/-account/-user/user-view';
import SurveyList from '../section/-examine/-survey/-survey-list/survey-list';
import SurveyView from '../section/-examine/-survey/survey-view';
import SurveySubmitList from '../section/-examine/-survey-submit/-survey-submit-list/survey-submit-list';
import SurveySubmitView from '../section/-examine/-survey-submit/survey-submit-view';
import { Role } from '../section/enum/role';
import SurveyRespondentList from '../section/-examine/-survey-respondent/-survey-respondent-list/survey-respondent-list';
import SurveyRespondentView from '../section/-examine/-survey-respondent/survey-respondent-view';
import { hasRouteAccess } from './route-access';
import AttendanceList from '../section/-education/-attendance/-attendance-list/attendance-list';
import AttendanceView from '../section/-education/-attendance/attendance-view';
import AverageRatingModuleList from '../section/-statistics/-average-rating/-average-rating-module-list/average-rating-module-list';
import AverageRatingBadgeList from '../section/-statistics/-average-rating/-average-rating-badge-list/average-rating-badge-list';
import ManagerDashboardView from '../section/-dashboard/-manager/dashboard-manager-view';
import StudentList from '../section/-account/-student/-student-list/student-list';
import StudentView from '../section/-account/-student/student-view'
import AssignmentManagementView from '../section/-assignment/-assignment-management/assignment-management-view';

export const ROUTE_CATEGORY_DASHBOARD_TITLE = "Dashboard";
export const ROUTE_CATEGORY_EDUCATION_MANAGMENT_TITLE = "Education management";
export const ROUTE_CATEGORY_EXAMINE_MANAGMENT_TITLE = "Examine management";
export const ROUTE_CATEGORY_STATISTICS_TITLE = "Statistics";
export const ROUTE_CATEGORY_ACCOUNT_MANAGEMENT_TITLE = "Account management";

export const ROUTE_SETUP: RouteCategory[] = [
    {
        title: ROUTE_CATEGORY_DASHBOARD_TITLE,
        routes: [
            {
                index: true,
                menu: true,
                name: "Home",
                roles: [],
                paths: [HOME],
                icon: <HomeIcon />,
                component: <Home />
            },
            {
                index: true,
                menu: true,
                name: "Manager",
                roles: [Role.Administrator, Role.CohortCaptain, Role.LeadTrainer],
                paths: [DASHBOARD_MANAGER],
                icon: <DashboardIcon />,
                component: <ManagerDashboardView />,
                description: "Under Manager dashboard you can see ratings, submissions, and evaluations for active cohorts."
            },
            {
                index: false,
                menu: true,
                name: "My assignments",
                roles: [Role.Student, Role.BootcampTrainee, Role.Explorer],
                paths: [WORK_MY_ASSIGNMENTS],
                icon: <AssignmentIcon />,
                component: <StudentHome />,
                description: "Under My assignments you can see and handle all your assignments in the cohort that you taking."
            },
            {
                index: false,
                menu: true,
                name: "My reviews",
                roles: [Role.Evaluator],
                paths: [WORK_MY_REVIEWS],
                icon: <AssignmentIcon />,
                component: <EvaluatorHome />,
                description: "Under My review you can see and handle all assignments producd by students for modules that you are assinged to evaluate."
            },
            {
                index: false,
                menu: false,
                name: "Assignment reassign",
                roles: [Role.Administrator, Role.CohortCaptain, Role.LeadTrainer],
                paths: [ASSIGNMENT_MANAGEMENT],
                component: <AssignmentManagementView />,
                description: "Under Assignment reassign you can see and handle all assignments producd by students for modules."
            }
        ]
    },
    {
        title: ROUTE_CATEGORY_EDUCATION_MANAGMENT_TITLE,
        routes: [
            {
                index: false,
                menu: true,
                name: "Modules",
                roles: [Role.Administrator, Role.CourseEditor, Role.CohortCaptain, Role.LeadTrainer],
                paths: [EDUCATION_MANAGEMENT_MODULE_LIST],
                icon: <AutoStoriesIcon />,
                component: <ModuleList />,
                description: "Under Modules you can see and manage all course modules, and their badges, available throught Learning Lions."
            },
            {
                index: false,
                menu: false,
                name: "Add module",
                roles: [Role.Administrator, Role.CourseEditor, Role.CohortCaptain, Role.LeadTrainer],
                paths: [EDUCATION_MANAGEMENT_MODULE_ADD],
                component: <ModuleView />
            },
            {
                index: false,
                menu: false,
                name: "Edit module",
                roles: [Role.Administrator, Role.CourseEditor, Role.CohortCaptain, Role.LeadTrainer],
                paths: [EDUCATION_MANAGEMENT_MODULE_EDIT],
                component: <ModuleView />
            },
            {
                index: false,
                menu: false,
                name: "Add badge",
                roles: [Role.Administrator, Role.CourseEditor, Role.CohortCaptain, Role.LeadTrainer],
                paths: [EDUCATION_MANAGEMENT_MODULE_BADGE_ADD],
                component: <BadgeView />
            },
            {
                index: false,
                menu: false,
                name: "Edit badge",
                roles: [Role.Administrator, Role.CourseEditor, Role.CohortCaptain, Role.LeadTrainer],
                paths: [EDUCATION_MANAGEMENT_MODULE_BADGE_EDIT],
                component: <BadgeView />
            },
            {
                index: false,
                menu: true,
                name: "Cohorts",
                roles: [Role.Administrator, Role.CohortCaptain, Role.LeadTrainer],
                paths: [EDUCATION_MANAGEMENT_COHORT_LIST],
                icon: <Diversity1Icon />,
                component: <CohortList />,
                description: "Under Cohorts you can see and manage all cohorts, the modules and students connected to them."
            },
            {
                index: false,
                menu: false,
                name: "Add Cohort",
                roles: [Role.Administrator, Role.CohortCaptain, Role.LeadTrainer],
                paths: [EDUCATION_MANAGEMENT_COHORT_ADD],
                component: <CohortView />,
            },
            {
                index: false,
                menu: false,
                name: "Edit Cohort",
                roles: [Role.Administrator, Role.CohortCaptain, Role.LeadTrainer],
                paths: [EDUCATION_MANAGEMENT_COHORT_EDIT],
                component: <CohortView />,
            },
            {
                index: false,
                menu: true,
                name: "Evaluators",
                roles: [Role.Administrator, Role.CohortCaptain, Role.LeadTrainer],
                paths: [EDUCATION_MANAGEMENT_EVALUATOR_LIST],
                icon: <RuleIcon />,
                component: <EvaluatorList />,
                description: "Under Evaluators you can see and manage all evaluators and assign them the modules they should review student work from."
            },
            {
                index: false,
                menu: false,
                name: "Add Evaluator",
                roles: [Role.Administrator, Role.CohortCaptain, Role.LeadTrainer],
                paths: [EDUCATION_MANAGEMENT_EVALUATOR_ADD],
                component: <EvaluatorView />,
            },
            {
                index: false,
                menu: false,
                name: "Assign modules to Evaluator",
                roles: [Role.Administrator, Role.CohortCaptain, Role.LeadTrainer],
                paths: [EDUCATION_MANAGEMENT_EVALUATOR_MODULE_ASSIGN],
                component: <EvaluatorView />,
            },
            {
                index: false,
                menu: true,
                name: "Presentations",
                roles: [Role.Administrator, Role.CohortCaptain, Role.LeadTrainer],
                paths: [EDUCATION_PRESENTATION_LIST],
                icon: <ForumOutlinedIcon />,
                component: <PresentationList />,
                description: "Under Presentation you can see and manage all presentations that has been given by the students, and what rating they got for it."
            },
            {
                index: false,
                menu: false,
                name: "Add presentation",
                roles: [Role.Administrator, Role.CohortCaptain, Role.LeadTrainer],
                paths: [EDUCATION_PRESENTATION_ADD],
                component: <PresentationView />
            },
            {
                index: false,
                menu: false,
                name: "Edit presentation",
                roles: [Role.Administrator, Role.CohortCaptain, Role.LeadTrainer],
                paths: [EDUCATION_PRESENTATION_EDIT],
                component: <PresentationView />
            },
            {
                index: false,
                menu: true,
                name: "Attendance",
                roles: [Role.Administrator, Role.CohortCaptain, Role.LeadTrainer],
                paths: [EDUCATION_ATTENDANCE_LIST],
                icon: <BeenhereOutlinedIcon />,
                component: <AttendanceList />,
                description: "Under Attendance you can see and manage all day-to-day student attendance to their education."
            },
            {
                index: false,
                menu: false,
                name: "Update attendance",
                roles: [Role.Administrator, Role.CohortCaptain, Role.LeadTrainer],
                paths: [EDUCATION_ATTENDANCE_UPDATE],
                component: <AttendanceView />
            },
        ]
    },
    {
        title: ROUTE_CATEGORY_EXAMINE_MANAGMENT_TITLE,
        routes: [
            {
                index: false,
                menu: true,
                name: "Surveys",
                roles: [Role.Administrator, Role.ExamineManager],
                paths: [EXAMINE_SURVEY_HANDLE_LIST],
                icon: <DescriptionOutlinedIcon />,
                component: <SurveyList />,
                description: "Under Surveys you can see and manage all surveys used to gather information from attendees, students and other LMS connected people."
            },
            {
                index: false,
                menu: false,
                name: "Add survey",
                roles: [Role.Administrator, Role.ExamineManager],
                paths: [EXAMINE_SURVEY_HANDLE_ADD],
                component: <SurveyView />
            },
            {
                index: false,
                menu: false,
                name: "Edit survey",
                roles: [Role.Administrator, Role.ExamineManager],
                paths: [EXAMINE_SURVEY_HANDLE_EDIT],
                component: <SurveyView />
            },
            {
                index: false,
                menu: true,
                name: "Submits",
                roles: [Role.Administrator, Role.ExamineManager],
                paths: [EXAMINE_SURVEY_SUBMIT_LIST],
                icon: <TaskOutlinedIcon />,
                component: <SurveySubmitList />,
                description: "Under Survey submits you can see and analyse all survey submission to see what individual respondents answered."
            },
            {
                index: false,
                menu: false,
                name: "View survey submit",
                roles: [Role.Administrator, Role.ExamineManager],
                paths: [EXAMINE_SURVEY_SUBMIT_VIEW],
                component: <SurveySubmitView />
            },
            {
                index: false,
                menu: true,
                name: "Respondents",
                roles: [Role.Administrator, Role.ExamineManager],
                paths: [EXAMINE_SURVEY_RESPONDENT_LIST],
                icon: <ContactPageOutlinedIcon />,
                component: <SurveyRespondentList />,
                description: "Under Survey respondents you can see and analyse all survey respondents to see which more surveys they have submitted."
            },
            {
                index: false,
                menu: false,
                name: "View survey respondent",
                roles: [Role.Administrator, Role.ExamineManager],
                paths: [EXAMINE_SURVEY_RESPONDENT_VIEW],
                component: <SurveyRespondentView />
            }
        ]
    },
    {
        title: ROUTE_CATEGORY_STATISTICS_TITLE,
        routes: [
            {
                index: false,
                menu: true,
                name: "Average rating",
                roles: [Role.Administrator, Role.CohortCaptain, Role.LeadTrainer],
                paths: [STATISTIC_AVERAGE_RATING_MODULE_LIST],
                icon: <BarChartIcon />,
                component: <AverageRatingModuleList />,
                description: "Under Average rating you can see modules and badges for a cohort, their average rating, and comparison to the common average."
            },
            {
                index: false,
                menu: false,
                name: "Average rating badge",
                roles: [Role.Administrator, Role.CohortCaptain, Role.LeadTrainer],
                paths: [STATISTIC_AVERAGE_RATING_BADGE_LIST],
                component: <AverageRatingBadgeList />
            }
        ]
    },
    {
        title: ROUTE_CATEGORY_ACCOUNT_MANAGEMENT_TITLE,
        routes: [
            {
                index: false,
                menu: true,
                name: "Users",
                roles: [Role.Administrator],
                paths: [ACCOUNT_MANAGEMENT_USER_LIST],
                icon: <PeopleOutlineOutlinedIcon />,
                component: <UserList />,
                description: "Under Users you can see and manage all users who have access, and what they have access to."
            },
            {
                index: false,
                menu: false,
                name: "Add user",
                roles: [Role.Administrator],
                paths: [ACCOUNT_MANAGEMENT_USER_ADD],
                component: <UserView />
            },
            {
                index: false,
                menu: false,
                name: "Edit user",
                roles: [Role.Administrator],
                paths: [ACCOUNT_MANAGEMENT_USER_EDIT],
                component: <UserView />
            },
            {
                index: false,
                menu: true,
                name: "Students",
                roles: [Role.Administrator, Role.CohortCaptain, Role.LeadTrainer],
                paths: [ACCOUNT_MANAGEMENT_STUDENT_LIST],
                icon: <SchoolIcon />,
                component: <StudentList />,
                description: "Under Students you can see and manage all student, handle student information and cohorts."
            },
            {
                index: false,
                menu: false,
                name: "Add student",
                roles: [Role.Administrator, Role.CohortCaptain, Role.LeadTrainer],
                paths: [ACCOUNT_MANAGEMENT_STUDENT_ADD],
                component: <StudentView />
            },
            {
                index: false,
                menu: false,
                name: "Edit student",
                roles: [Role.Administrator, Role.CohortCaptain, Role.LeadTrainer],
                paths: [ACCOUNT_MANAGEMENT_STUDENT_EDIT],
                component: <StudentView />
            }
        ]
    }
]

export const getAccessibleRoutes = (roles: Role[]) => {
    return ROUTE_SETUP.flatMap(_ => _.routes)
        .filter(route => route.menu && route.description && hasRouteAccess(route.roles, roles));
}