import { useEffect, useState } from 'react';
import commonStyles from "../../../style/common.module.css";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import StarIcon from '@mui/icons-material/Star';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { ModuleAssignment } from '../../model/module-assignment';
import { BadgeAssignment } from '../../model/badge-assignment';
import { LoadingButton } from '@mui/lab';
import { useDialog } from '../../../../context/dialog/dialog-provider';
import { DialogType, informationText } from '../../../../context/dialog/dialog-context-type';
import { getNumber } from '../../../../utils/helper/string-helper';
import EvaluatorJournalBadgeContent from './evaluator-journal-badge-content';
import { AssignmentStatus } from '../../enum/assignment-status';
import { useSearchParams } from 'react-router-dom';
import {
    ASSIGNMENT_QUERY_PARAMETER_MODULEID,
    ASSIGNMENT_QUERY_PARAMETER_TAB_VALUE_JOURNAL,
    ASSIGNMENT_QUERY_PARAMETER_WORKITEMID
} from '../../utils/assignment-constants';
import { getBadgeTerminology, getGeneralCourseLevel, getModuleCourseLevel, getModuleTerminology, getStudentTerminology } from '../../utils/terminology';
import { CourseLevel } from '../../../-education/enum/course-level';
import { updateSearchParams } from '../../utils/assignment-navigation';
import dayjs from 'dayjs';

export default function EvaluatorJournal({
    generalCourseLevel,
    reviewedModuleAssignments,
    updateModuleAssignments,
    editHourLimit,
    assignmentDaysBack,
}: {
    generalCourseLevel: CourseLevel,
    reviewedModuleAssignments: ModuleAssignment[],
    updateModuleAssignments(badgeAssignment: BadgeAssignment): void,
    editHourLimit: number,
    assignmentDaysBack: number
}) {
    const [searchParams, setSearchParams] = useSearchParams();
    const moduleId = searchParams.get(ASSIGNMENT_QUERY_PARAMETER_MODULEID);
    const workItemId = Number(searchParams.get(ASSIGNMENT_QUERY_PARAMETER_WORKITEMID));
    const [reviewedBadgeAssignment, setReviewedBadgeAssignment] = useState<BadgeAssignment>(reviewedModuleAssignments.find(_ => _.moduleId === moduleId)?.badges.find(_ => _.workItemId === workItemId) ?? {});

    const dialog = useDialog();

    const evaluatorCourseLevel = reviewedModuleAssignments.length > 0 ? getGeneralCourseLevel(reviewedModuleAssignments) : generalCourseLevel;

    useEffect(() => {
        updateSearchParams(searchParams, setSearchParams, reviewedBadgeAssignment, ASSIGNMENT_QUERY_PARAMETER_TAB_VALUE_JOURNAL);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reviewedBadgeAssignment]);

    const getBadges = () => {
        const module = reviewedModuleAssignments.find(_ => _.moduleId === reviewedBadgeAssignment.moduleId);
        return module?.badges.filter(_ => _.submissionDate !== undefined && _.evaluatedDate !== undefined) ?? [] as BadgeAssignment[];
    }

    const handleBadgeSelect = (badgeAssignment: BadgeAssignment) => {
        if (badgeAssignment.workItemId !== reviewedBadgeAssignment.workItemId) {
            setReviewedBadgeAssignment(badgeAssignment);
        } else {
            setReviewedBadgeAssignment({ ...reviewedBadgeAssignment, workItemId: undefined });
        }
    }

    const openHelpInformationDialog = () => {
        dialog.openDialog(DialogType.INFORMATION, "Help / Information", [
            informationText(`In the list of <b>
                ${getBadgeTerminology(evaluatorCourseLevel, true)} submissions</b> review, you can see the <b>rating</b> you gave. It will be empty until you leaves a rating, or a comment.`),
            informationText(`The list of <b>
                ${getBadgeTerminology(evaluatorCourseLevel, true)} submissions</b> review are <b>ordered by review time</b>, the more recent review the higher in the list.`),
            informationText(`The list of <b>
                ${getBadgeTerminology(evaluatorCourseLevel, true)} submissions</b> review will not show reviews that ar older then ${assignmentDaysBack} days.`),
            informationText(`You can review a <b>
                ${getBadgeTerminology(evaluatorCourseLevel, true)} submission</b> with a <b>comment and no rating</b> to informing the 
                ${getStudentTerminology(generalCourseLevel, true)} about something. A comment icon will indicate this in the list.`),
            informationText(`After you opened a <b>
                ${getBadgeTerminology(evaluatorCourseLevel, true)} submission</b> review then you can <b>Edit</b> it. To do so, click on the menu in the top right corner of the 
                ${getBadgeTerminology(evaluatorCourseLevel, true)} submission review, there you will find the edit option.`),
            informationText(`After you added a rating to a <b>
                ${getBadgeTerminology(evaluatorCourseLevel, true)} submission</b> review then you can only <b>edit it for 
                ${editHourLimit-24} hours</b>, after which it is no longer possible.`)
        ]);
    }

    return (
        <div className={commonStyles.pb} >
            {reviewedModuleAssignments.length > 0 && <FormControl id={`module-form-control`} sx={{ flex: '1 1 100%', alignSelf: 'flex-start', width: '100%', paddingBottom: '1rem' }}>
                <InputLabel id={`module-label`}>{getModuleTerminology(getModuleCourseLevel(reviewedModuleAssignments, reviewedBadgeAssignment.badgeId))}</InputLabel>
                <Select
                    labelId={`module-label`}
                    id={`module-select`}
                    value={reviewedBadgeAssignment.moduleId ?? ''}
                    label={getModuleTerminology(evaluatorCourseLevel)}
                    onChange={e => setReviewedBadgeAssignment({
                        ...reviewedBadgeAssignment,
                        moduleId: e.target.value,
                        workItemId: undefined
                    })}
                >
                    {reviewedModuleAssignments.map(_ => {
                        return <MenuItem key={_.moduleId} id={`${_}-menu-item`} value={_.moduleId}>
                            {_.moduleName}
                        </MenuItem>
                    })}
                </Select>
            </FormControl>}
            {reviewedModuleAssignments.length === 0 && <div>
                <span>Your <b>Journal</b> is empty, when you have reviewed a ${getBadgeTerminology(evaluatorCourseLevel, true)} then you can view, and futher handle, it from here.</span>
            </div>}
            {reviewedBadgeAssignment.moduleId !== undefined && <div className={commonStyles.mb}>
                {getBadges().map(_ => {
                    return (<Accordion key={_.workItemId} expanded={reviewedBadgeAssignment.workItemId === _.workItemId} onChange={() => handleBadgeSelect(_)}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography sx={{ width: '99%', flexShrink: 0, }}>
                                <span>{dayjs(_.evaluatedDate).format('YYYY-MM-DD')} - {_.badgeName}</span>
                                {getNumber(_.rating) > 0 && Array.from({ length: getNumber(_.rating) }, () => <StarIcon fontSize="small" key={`${Math.random()}-select-icon`} color="primary" sx={{ float: 'right' }} />)}
                                {_.assignmentStatus === AssignmentStatus.Evaluated_Commented && <ChatBubbleIcon color="primary" sx={{ float: 'right' }} />}
                            </Typography>
                        </AccordionSummary>
                        {reviewedBadgeAssignment.workItemId === _.workItemId && <AccordionDetails>
                            <EvaluatorJournalBadgeContent
                                initialBadge={reviewedBadgeAssignment}
                                updateBadge={(badge) => {
                                    updateModuleAssignments(badge);
                                    setReviewedBadgeAssignment(badge);
                                }} 
                                moduleCourseLevel={getModuleCourseLevel(reviewedModuleAssignments, reviewedBadgeAssignment.badgeId)}/>
                        </AccordionDetails>}
                    </Accordion>)
                })}
            </div>}
            {reviewedModuleAssignments.length > 0 && <div style={{ display: 'flex', justifyContent: 'end' }}>
                <LoadingButton
                    id={`info-button`}
                    loadingPosition="start"
                    startIcon={<HelpOutlineIcon />}
                    variant="outlined"
                    onClick={() => openHelpInformationDialog()}
                >
                    Info / Help
                </LoadingButton>
            </div>}
        </div>
    );
}