import { useEffect, useRef, useState } from "react";
import styles from "../style/public.module.css";
import happyLion from "../../../assets/happy_lion.png";
import confusedLion from "../../../assets/confused_lion.png";
import { useParams } from "react-router-dom";
import { AnswerType } from "../../enum/answer-type";
import { Survey } from "../../-examine/model/survey";
import { surveyFieldDefinitions } from "./survey-field-definition";
import { SurveySubmitAnswer } from "../../-examine/model/survey-submit-answer";
import { SurveySubmit } from "../../-examine/model/survey-submit";
import { useToast } from "../../../context/toast/toast-provider";
import { getSurvey, surveySubmit } from "../service/public-service";
import dayjs from "dayjs";
import { FieldDefinition } from "../../../component/form/field-definition";
import { SelectItem } from "../../../component/form/select-item";
import BaseForm from "../../../component/form/base-form";

export enum SurveyState {
    Loading = 1,
    Ready = 2,
    Submitted = 3,
    NotFound = 4
}

interface Answers {
    [key: string]: any
}

export default function SurveyForm() {
    const { surveyId } = useParams();
    let survey = useRef<Survey | undefined>();

    const [loading, setLoading] = useState<boolean>(true);
    const [submitting, setSubmitting] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [answers, setAnswers] = useState<Answers>();
    const [questions, setQuestions] = useState<FieldDefinition[]>([]);

    const toast = useToast();

    useEffect(() => {
        setLoading(true);
        getSurvey(Number(surveyId)).then(result => {
            if (result !== undefined) {
                let surveyAnswers = {} as Answers
                const surveyQuestions = result.questions.map(_ => {

                    surveyAnswers[`Q_${_.answerType}_${_.sequence}`] = _.answerType === AnswerType.Boolean ? false : "";

                    const values = _.answers.map(a => {
                        return {
                            id: a.text,
                            name: a.text
                        } as SelectItem
                    });

                    return {
                        id: `Q_${_.answerType}_${_.sequence}`,
                        name: _.text,
                        type: AnswerType[_.answerType].replace('_', '-').toLowerCase(),
                        required: _.answerRequired,
                        disabled: false,
                        values: values
                    } as FieldDefinition
                });

                surveyFieldDefinitions.forEach(_ => {
                    surveyAnswers[_.id] = ""
                });

                setQuestions(surveyFieldDefinitions.concat(surveyQuestions));
                setAnswers(surveyAnswers);
            }

            survey.current = result;
            setLoading(false);
        })
    }, [surveyId]);

    const getQuestionSequence = (id: string) => {
        const lastSection = id.lastIndexOf('_');
        return Number(id.substring(lastSection + 1));
    }

    const getAnswerString = (id: string, answer: any) => {
        const firstSection = id.replace('Q_', '').indexOf('_');
        const answerType = Number(id.replace('Q_', '').substring(0, firstSection));

        switch (answerType) {
            case AnswerType.Text:
            case AnswerType.Text_Area:
            case AnswerType.Select:
            case AnswerType.Radio:
                return answer as string;
            case AnswerType.Multi_Select:
                const answerList = (answer as string[]);
                return answerList.join(', ');
            case AnswerType.Date:
                return dayjs(answer as string).format('YYYY-MM-DD');
            case AnswerType.Boolean:
                const answerBool = (answer as boolean);
                return answerBool === true ? 'True' : 'False';
            default:
                return ''
        }
    }

    const submitValidation = (answers: Answers, errors: any) => {
        if (errors['phoneNumber'] === undefined) {
            const phoneNumber = (answers['phoneNumber'] as string).replace(/ /g, '');

            if (phoneNumber.startsWith('0') === true) {
                return {
                    ...errors,
                    phoneNumber: `${phoneNumber} is not a valid Phone number, it should not start with 0.`
                };
            } else if (phoneNumber.length !== 9) {
                return {
                    ...errors,
                    phoneNumber: `${phoneNumber} is not a valid Phone number, it should have 9 digits.`
                };
            } 
        }
        
        return errors;
    }

    const handleSubmit = (answers: Answers) => {
        setSubmitting(true);

        const surveySubmitAnswer = [] as SurveySubmitAnswer[]

        for (var prop in answers) {
            if (Object.prototype.hasOwnProperty.call(answers, prop)) {
                const sequence = getQuestionSequence(prop);
                if (!isNaN(sequence)) {
                    surveySubmitAnswer.push({
                        surveyQuestionId: survey.current?.questions.find(_ => _.sequence === sequence)?.id,
                        answer: getAnswerString(prop, answers[prop])
                    });
                }
            }
        }

        const newSurveySubmit = {
            name: answers['name'],
            phoneNumber: answers['phoneNumber'].replace(/ /g, ''),
            emailAddress: answers['emailAddress'],
            answers: surveySubmitAnswer
        } as SurveySubmit;

        surveySubmit(Number(surveyId), newSurveySubmit).then(() => {
            setSubmitting(false);
            setSubmitted(true);
        }).catch(() => {
            toast.addToast(`Unable to submit survey :( Try again later!`, "error");
            setSubmitting(false);
        });
    }

    const getSurveyState = () => {
        if (loading === true) {
            return SurveyState.Loading;
        } else if (submitted === true) {
            return SurveyState.Submitted;
        } else if (survey.current !== undefined) {
            return SurveyState.Ready;
        } else if (survey.current === undefined) {
            return SurveyState.NotFound;
        }
    }

    return (
        <div className={styles.surveyContent} >
            <div className={styles.survey}>
                {getSurveyState() === SurveyState.Ready && <div>
                    <h1>{survey.current?.title}</h1>
                    <p
                        dangerouslySetInnerHTML={{
                            __html: survey.current?.description.replace(/\n/g, '<br />') ?? ''
                        }}>
                    </p>
                    <br />
                    <BaseForm
                        name={`${survey.current?.title}`}
                        model={answers}
                        setModel={setAnswers}
                        fields={questions}
                        inputFull={true}
                        submitValidation={submitValidation}
                        submitEntity={handleSubmit}
                        submitButtonText={'Submit survey'}
                        submitting={submitting}
                    />
                </div>}
                {getSurveyState() === SurveyState.Submitted && <div className={styles.surveyInfo} >
                    <div>
                        <div>
                            <img src={happyLion} alt='Happy lion' />
                        </div>
                        <h1>Thank you!</h1>
                        <p>We at Learning Lion are super grateful to recieve your submitted survey!<br />Have a great day!</p>
                    </div>
                </div>}
                {getSurveyState() === SurveyState.NotFound && <div className={styles.surveyInfo} >
                    <div>
                        <div>
                            <img src={confusedLion} alt='Confused lion' />
                        </div>
                        <h1>Survey not found!</h1>
                        <p>The survey you are requesting.... We can't find it...<br />Make sure you have to correct link!</p>
                    </div>
                </div>}
            </div>
        </div>
    );
}
