import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { ErrorBoundary } from 'react-error-boundary'
import CacheBuster from 'react-cache-buster';
import ToastProvider from "./context/toast/toast-provider";
import AuthProvider from "./context/auth/auth-provider";
import RequireAuth from "./auth/require-auth";
import logo from "./assets/learning_lions_logo.png";
import styles from "./section/style/login.module.css";
import { getVersion } from "./section/service/utils-service";
import UnknownError from "./section/error/unknown-error";
import { ROUTE_SETUP } from "./infrastructure/route-setup";
import Layout from "./section/layout";
import { LOGIN, NO_ACCESS, PUBLIC_SURVEY } from "./infrastructure/route";
import NoAccess from "./section/error/no-access";
import NoRoute from "./section/error/no-route";
import Login from "./section/login";
import DialogProvider from "./context/dialog/dialog-provider";
import CacheProvider from "./context/cache/cache-provider";
import SurveyForm from "./section/-public/-survey/survey";
import ViewLoader from "./component/misc/view-loader";

export default function App() {
    const [loading, setLoading] = useState<boolean>(true);
    const [version, setVersion] = useState<string>('');

    useEffect(() => {
        setLoading(true);
        getVersion().then(result => {
            setVersion(result);
            setLoading(false);
        });
    }, []);

    const LoadingPrompt = () => {
        return <div className={styles.prompt}>
            <img style={{ width: '100%' }} id="ll-logo" src={logo} alt="Learning lions logo" />
        </div>
    }

    return (
        <React.Fragment>
            {loading === true && window.location.pathname.startsWith('/public/') === false && <LoadingPrompt />}
            {loading === true && window.location.pathname.startsWith('/public/') === true && <ViewLoader loading={true} />}
            {loading === false && <ErrorBoundary FallbackComponent={UnknownError} >
                <ToastProvider>
                    <DialogProvider>
                        <AuthProvider>
                            <CacheProvider>
                                <CacheBuster
                                    currentVersion={version}
                                    isEnabled={process.env.NODE_ENV === 'production'}
                                    isVerboseMode={false}
                                    metaFileDirectory={'.'}
                                    loadingComponent={<LoadingPrompt />}
                                >
                                    <Routes>
                                        <Route path="/" element={<Layout />}>
                                            {ROUTE_SETUP.map(c => {
                                                return c.routes.map(r => {
                                                    return r.paths.map(p => {
                                                        return <Route index={r.index} key={p} path={p} element={
                                                            <RequireAuth roles={r.roles}>
                                                                {r.component}
                                                            </RequireAuth>}
                                                        />
                                                    })
                                                })
                                            })}
                                            <Route path={NO_ACCESS} element={
                                                <RequireAuth roles={[]}>
                                                    <NoAccess />
                                                </RequireAuth>} />
                                            <Route path="*" element={
                                                <RequireAuth roles={[]}>
                                                    <NoRoute />
                                                </RequireAuth>} />
                                            <Route path={LOGIN} element={<Login />} />
                                            <Route path={PUBLIC_SURVEY} element={<SurveyForm />} />
                                        </Route>
                                    </Routes>
                                </CacheBuster>
                            </CacheProvider>
                        </AuthProvider>
                    </DialogProvider>
                </ToastProvider>
            </ErrorBoundary>}
        </React.Fragment>
    );
}
