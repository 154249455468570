import React, { useEffect, useState } from "react";
import DirectionsIcon from '@mui/icons-material/Directions';
import { useToast } from "../../../../context/toast/toast-provider";
import BaseForm from "../../../../component/form/base-form";
import { BadgeAssignment } from "../../model/badge-assignment";
import { ModuleAssignment } from "../../model/module-assignment";
import { FieldDefinition } from "../../../../component/form/field-definition";
import { SelectItem } from "../../../../component/form/select-item";
import { reassignEvaluatorAssignment, updateEvaluatorAssignment } from "../../service/assignment-service";
import { evaluatorReviewSelectFieldDefinitions } from "./evaluator-review-select-field-definition";
import { evaluatorReviewFieldDefinitions } from "./evaluator-review-field-definition";
import { isValidHttpUrl } from "../../../../utils/helper/string-helper";
import { useSearchParams } from "react-router-dom";
import {
    ASSIGNMENT_QUERY_PARAMETER_MODULEID,
    ASSIGNMENT_QUERY_PARAMETER_TAB_VALUE_EVALUATOR_REVIEW,
    ASSIGNMENT_QUERY_PARAMETER_WORKITEMID
} from "../../utils/assignment-constants";
import { getBadgeTerminology, getGeneralCourseLevel, getModuleCourseLevel, getModuleTerminology, getStudentTerminology } from "../../utils/terminology";
import { CourseLevel } from "../../../-education/enum/course-level";
import { updateSearchParams } from "../../utils/assignment-navigation";
import { informationText } from "../../../../context/dialog/dialog-context-type";
import { ApiError } from "../../../../utils/api/api-error";
import { ApiErrorType } from "../../../../utils/api/api-error-type";

export interface AssignmentFilter {
    moduleId?: string | undefined,
    workItemId?: number | undefined
}

export default function EvaluatorReview({
    generalCourseLevel,
    unreviewedModuleAssignments,
    updateModuleAssignments,
    removeBadgeAssignment
}: {
        generalCourseLevel: CourseLevel,
        unreviewedModuleAssignments: ModuleAssignment[],
        updateModuleAssignments(badgeAssignment: BadgeAssignment): void
        removeBadgeAssignment(workItemId: number): void
}) {
    const [searchParams, setSearchParams] = useSearchParams();
    const moduleId = searchParams.get(ASSIGNMENT_QUERY_PARAMETER_MODULEID);
    const workItemId = Number(searchParams.get(ASSIGNMENT_QUERY_PARAMETER_WORKITEMID));
    const [filter, setFilter] = useState<AssignmentFilter>({
        moduleId: moduleId ?? undefined,
        workItemId: workItemId > 0 ? workItemId : undefined
    });

    const [reviewBadgeAssignment, setReviewBadgeAssignment] = useState<BadgeAssignment>({ comment: "" });
    const [submitting, setSubmitting] = useState(false);
    const [reassigning, setReassigning] = useState(false);

    const toast = useToast();

    const evaluatorCourseLevel = unreviewedModuleAssignments.length > 0 ? getGeneralCourseLevel(unreviewedModuleAssignments) : generalCourseLevel;

    useEffect(() => {
        const workItem = unreviewedModuleAssignments.flatMap(_ => _.badges).find(_ => _.workItemId === filter.workItemId);

        if (workItem !== undefined && workItem.moduleId === filter.moduleId) {
            setReviewBadgeAssignment({ ...workItem, comment: "" });
        } else {
            setReviewBadgeAssignment({ comment: "" });
        }
    }, [unreviewedModuleAssignments, filter]);

    useEffect(() => {
        updateSearchParams(searchParams, setSearchParams, reviewBadgeAssignment, ASSIGNMENT_QUERY_PARAMETER_TAB_VALUE_EVALUATOR_REVIEW);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reviewBadgeAssignment]);

    const getReviewSelectFieldDefinitions = () => {
        const modules = unreviewedModuleAssignments.map(_ => {
            let selectItem: SelectItem = {
                id: _.moduleId,
                name: _.moduleName
            }
            return selectItem;
        });

        const selectedModule = unreviewedModuleAssignments?.find(_ => _.moduleId === filter.moduleId);

        if (filter.moduleId !== undefined && selectedModule === undefined) {
            setFilter({ ...filter, moduleId: undefined });
        }

        evaluatorReviewSelectFieldDefinitions.find(_ => _.id === "moduleId")!.values = modules;
        evaluatorReviewSelectFieldDefinitions.find(_ => _.id === "workItemId")!.disabled = true;

        if (filter.moduleId !== undefined) {
            const badges = selectedModule?.badges.filter(_ => _.submissionDate !== undefined && _.evaluatedDate === undefined).map(_ => {
                let selectItem: SelectItem = {
                    id: _.workItemId,
                    name: `${_.badgeName ?? _.workItemId} ${_.resubmits !== undefined && _.resubmits > 0 ? '(Resubmitted)' : ''}`
                }
                return selectItem;
            });

            if (filter.workItemId !== undefined && badges?.find(_ => _.id === filter.workItemId) === undefined) {
                setFilter({ ...filter, workItemId: undefined });
            }

            evaluatorReviewSelectFieldDefinitions.find(_ => _.id === "workItemId")!.name = getBadgeTerminology(selectedModule?.moduleCourseLevelId ?? evaluatorCourseLevel);
            evaluatorReviewSelectFieldDefinitions.find(_ => _.id === "workItemId")!.values = badges;
            evaluatorReviewSelectFieldDefinitions.find(_ => _.id === "workItemId")!.disabled = false;
        }

        evaluatorReviewSelectFieldDefinitions.find(_ => _.id === "moduleId")!.name = getModuleTerminology(selectedModule?.moduleCourseLevelId ?? evaluatorCourseLevel);

        return evaluatorReviewSelectFieldDefinitions;
    }

    const getReviewFieldDefinitions = (): FieldDefinition[] => {
        const badgeTerminology = getBadgeTerminology(getModuleCourseLevel(unreviewedModuleAssignments, reviewBadgeAssignment.badgeId))

        evaluatorReviewFieldDefinitions.find(_ => _.id === "badgeInstruction")!.name = `${badgeTerminology} instructions`;
        evaluatorReviewFieldDefinitions.find(_ => _.id === "badgeAcceptanceCriteria")!.name = `${badgeTerminology} acceptance criteria`;
        evaluatorReviewFieldDefinitions.find(_ => _.id === "workingBadgePath")!.name = `${badgeTerminology} path`;
        evaluatorReviewFieldDefinitions.find(_ => _.id === "workingBadgePath")!.type = isValidHttpUrl(reviewBadgeAssignment.workingBadgePath) ? 'link' : 'info';
        evaluatorReviewFieldDefinitions.find(_ => _.id === "rating")!.valueSuffixes = reviewBadgeAssignment.allowResubmit ? [{ value: 1, suffix: '(Can be resubmitted)'}] : undefined;
        return evaluatorReviewFieldDefinitions;
    }

    const submitValidation = (badgeAssignment: BadgeAssignment, errors: any) => {
        const commentGiven = (badgeAssignment.comment !== undefined && badgeAssignment.comment !== '');
        const ratingGiven = (badgeAssignment.rating !== undefined && badgeAssignment.rating !== '' && Number(badgeAssignment.rating) !== 0);

        if (!commentGiven && !ratingGiven) {
            return {
                ...errors,
                rating: `Please provide either a comment or set a review rating`,
                comment: `Please provide either a comment or set a review rating`
            };
        }
        return errors
    }

    const handleSubmit = (badgeAssignment: BadgeAssignment) => {
        setSubmitting(true);

        badgeAssignment.rating = badgeAssignment.rating?.toString();
        const moduleCourseLevel = getModuleCourseLevel(unreviewedModuleAssignments, badgeAssignment.badgeId);

        updateEvaluatorAssignment(badgeAssignment).then(result => {
            toast.addToast(`Great work! ${getBadgeTerminology(moduleCourseLevel)} ${badgeAssignment.badgeName} submission has been reviewed`, "success");

            result.moduleId = badgeAssignment.moduleId;
            updateModuleAssignments(result);

            setReviewBadgeAssignment({ comment: "" });
            setFilter({ ...filter, workItemId: undefined });
            setSubmitting(false);
        }).catch(() => {
            toast.addToast(`Unable to review ${getBadgeTerminology(moduleCourseLevel, true)} ${badgeAssignment.badgeName} submission`, "error");
            setSubmitting(false);
        });
    }
    const handleReassign = () => {
        setReassigning(true);

        const moduleCourseLevel = getModuleCourseLevel(unreviewedModuleAssignments, reviewBadgeAssignment.badgeId);

        reassignEvaluatorAssignment(reviewBadgeAssignment.workItemId!).then(() => {
            toast.addToast(`${getBadgeTerminology(moduleCourseLevel)} ${reviewBadgeAssignment.badgeName} submission has been reassigned`, "success");
            
            removeBadgeAssignment(reviewBadgeAssignment.workItemId!);
            setReviewBadgeAssignment({ comment: "" });

            setFilter({ ...filter, workItemId: undefined });
            setReassigning(false);
        }).catch((error) => {
            if (((error as ApiError).apiErrorResponse).errorType === ApiErrorType.Assignment_CanNotReassign as number) {
                toast.addToast(`Unable to reassign ${getBadgeTerminology(moduleCourseLevel)} submission, no other available evaluator`, "error");
            } else {
                toast.addToast(`Unable to reassign ${getBadgeTerminology(moduleCourseLevel)} submission`, "error");
            }
            setReassigning(false);
        });
    }

    const openHelpInformationDialog = () => {
        var moduleAssignment = unreviewedModuleAssignments.filter(_ => _.moduleId === moduleId) ?? [];
        return [`The selected <b>
                ${getBadgeTerminology(getModuleCourseLevel(moduleAssignment, reviewBadgeAssignment.badgeId), true)} submissions</b> can be resubmitted by the <b>
                ${getStudentTerminology(generalCourseLevel, true)}</b> if the rating is set to 0 or 1.`
        ];
    }

    return (
        <React.Fragment>
            {unreviewedModuleAssignments.length > 0 && <BaseForm
                model={filter}
                setModel={setFilter}
                fields={getReviewSelectFieldDefinitions()}
            />}
            {reviewBadgeAssignment.workItemId !== undefined && <BaseForm
                model={reviewBadgeAssignment}
                setModel={setReviewBadgeAssignment}
                fields={getReviewFieldDefinitions()}
                submitValidation={submitValidation}
                submitEntity={handleSubmit}
                submitting={submitting}
                submitButtonText='Submit'
                
                customButtonText='Reassign'
                customButtonIcon={<DirectionsIcon />}
                customActionLoading={reassigning}
                customButtonConfirmationText={[informationText(`Are you sure you want to reassign 
                    ${getBadgeTerminology(getModuleCourseLevel(unreviewedModuleAssignments.filter(_ => _.moduleId === moduleId) ?? [], reviewBadgeAssignment.badgeId), true)}<b>
                    ${reviewBadgeAssignment.badgeName}</b>?`)]}
                customAction={handleReassign} 
                
                helpInformationText={openHelpInformationDialog()}
            />}
            {unreviewedModuleAssignments.length === 0 && <div>
                <span>You have no {getBadgeTerminology(evaluatorCourseLevel, true)} to <b>Review</b>. You can look at your previous {getBadgeTerminology(evaluatorCourseLevel, true)} reviews under your <b>Journal</b>.
                </span>
            </div>}
        </React.Fragment>
    );
}